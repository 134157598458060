// @flow
import React from 'react';
import type {Node} from 'react';
import {
  Icon,
  IconNames,
  Progress,
  ProgressTypes,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import type {UIIntegrationType} from '@wellstone-solutions/common';
import type {
  IntegrationMemberType,
  IntegrationSearchStatusType,
} from '../types';
import {INTEGRATION_STATUSES} from '../constants';
import {GCS_BASE_URL, GCS_DEFAULT_BUCKET} from 'constants/CloudStorage';

type PropsType = {
  form: any,
  memberType: IntegrationMemberType,
  orgIntegration: UIIntegrationType,
  status: IntegrationSearchStatusType,
};

export const LogoStatus = ({
  form,
  memberType,
  orgIntegration,
  status,
}: PropsType): Node => {
  const {
    READY,
    CONNECTED,
    SEARCHING,
    ERROR,
    CONFIRM,
    TIP,
  } = INTEGRATION_STATUSES;

  const logoUrl = `${GCS_BASE_URL}/${GCS_DEFAULT_BUCKET}/${String(
    orgIntegration?.logo,
  )}`;
  let statusIcon = IconNames.UserCircle;
  let statusIconColor = Theme.colorPalette.darkest;
  let statusLabel = '';

  switch (status) {
    case READY:
      statusIcon = IconNames.UserCircle;
      statusLabel =
        memberType === 'member'
          ? 'Search by \n Member ID'
          : 'Search by Staff \n Member ID';
      break;

    case CONNECTED:
    case CONFIRM:
      statusIcon = IconNames.UserBadgeCheck;
      statusIconColor = Theme.colorPalette.green;
      statusLabel = form.values?.externalRelationshipId
        ? 'Connected'
        : 'Not connected';
      break;
    case ERROR:
      statusIcon = IconNames.WarningTriangleSolid;
      statusIconColor = Theme.colorPalette.orange;
      statusLabel = 'No match found';
      break;
  }

  return (
    <Stack
      sx={{
        flex: 1,
        alignItems: 'center',
      }}>
      <img
        style={{width: 130, marginBottom: 8}}
        alt={`${orgIntegration?.name} logo`}
        src={logoUrl}
      />

      {[READY, CONNECTED, ERROR].includes(status) && (
        <>
          <Icon
            name={statusIcon}
            size={45}
            color={statusIconColor}
            style={{
              borderRadius: status === READY ? '50%' : 0,
              background:
                status === READY ? Theme.colorPalette.lightest : 'transparent',
              strokeWidth: 2,
            }}
          />
          <Typography
            sx={{
              color: Theme.colorPalette.darker,
              display: 'block',
              fontWeight: 'bold',
              mt: 1,
              whiteSpace: 'pre-line',
              textAlign: 'center',
            }}>
            {statusLabel}
          </Typography>
        </>
      )}

      {status === SEARCHING && (
        <Progress
          progressType={ProgressTypes.linear}
          sx={{mt: 6, width: '60%', alignSelf: 'center'}}
        />
      )}

      {status === CONFIRM && (
        <Typography
          sx={{
            color: Theme.colorPalette.darker,
            display: 'block',
            fontWeight: 'bold',
            mt: 1,
            whiteSpace: 'pre-line',
          }}>
          {statusLabel}
        </Typography>
      )}

      {status === TIP && (
        <>
          <Icon
            name={IconNames.LightBulbOn}
            size={45}
            color={statusIconColor}
            style={{
              strokeWidth: 2,
            }}
          />
          <Typography
            sx={{
              color: Theme.colorPalette.darker,
              display: 'block',
              fontWeight: 'bold',
              mt: 1,
              whiteSpace: 'pre-line',
            }}>
            QuickTip
          </Typography>
        </>
      )}
    </Stack>
  );
};
