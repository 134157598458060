// @flow
import React from 'react';
import {observer} from 'mobx-react';
import type {ComponentType} from 'react';
import type {RouteType} from 'types';

// Bulma
import Button from 'react-bulma-components/lib/components/button';
import Content from 'react-bulma-components/lib/components/content';
import Menu from 'react-bulma-components/lib/components/menu';

import {Icon, IconNames} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import NavLink from '../../tutorial/navlink';
import {NavCounter} from './NavCounter';
import {Branding} from './Branding';
import PfModal from '../../modal/PfModal';

type PropsType = {
  routes: Array<RouteType>,
};

export const SidePanel: ComponentType<PropsType> = observer(({routes}) => {
  const stores = useStores();
  const {appStore, channelStore, meStore} = stores;
  const {menuShowing} = appStore;
  const {me, signOut} = meStore;

  const getCountForRouteName = (name: string): number => {
    if (name === NAVIGATION_NAMES.conversations) {
      return channelStore.unreadMessagesTotal;
    }

    return 0;
  };

  return (
    <div
      className="side-menu-container"
      style={{
        ...styles.sideMenuContainer,
        ...{
          width: menuShowing ? 300 : 0,
          minWidth: menuShowing ? 300 : 0,
          opacity: menuShowing ? 1 : 0,
        },
      }}>
      <div style={styles.sideMenuCard}>
        <Branding />
        <div style={styles.sideMenuContent}>
          <hr />
          <Menu>
            <Menu.List>
              {routes.map((route) => {
                const isAllowed =
                  route.allowed && route.allowed.includes(me.membership.role);

                if (isAllowed && route.showInMenu) {
                  return (
                    <React.Fragment key={route.name}>
                      <Menu.List.Item renderAs="div" style={styles.listItem}>
                        <NavLink
                          activeClassName="is-active"
                          data-testid={`main-nav-${route.name
                            .toLowerCase()
                            .replace(' ', '-')}`}
                          style={styles.listItemLink}
                          to={route.path}
                          key={meStore.searchBoxTally}
                          id={`side-panel-route-${route.path.split('/').pop()}`}
                          tutorialStyles={styles.tutorialStyles}>
                          <span style={styles.name}>{route.name}</span>
                          <NavCounter
                            count={getCountForRouteName(route.name)}
                          />
                        </NavLink>
                      </Menu.List.Item>
                      {route.isBottomRuled && <hr />}
                    </React.Fragment>
                  );
                }

                return null;
              })}
            </Menu.List>
          </Menu>
          <hr />
          <Content style={styles.grow}>
            <div style={styles.sideMenuItem}>{me.name}</div>
            <Button
              color="primary"
              data-testid="sign-out-button"
              style={styles.sideMenuItem}
              onClick={signOut}>
              Sign out{' '}
              <Icon
                name={IconNames.LogOut}
                style={styles.mLeft}
                color={Theme.colorPalette.lightest}
              />
            </Button>
          </Content>
          {/* Restore Handbook when we get a new one: PAT-4269 */}
          <Content style={styles.content}>
            <PfModal
              buttonStyle={{...styles.sideMenuItem, ...styles.handbookButton}}
              icon={{name: IconNames.Book, color: Theme.colorPalette.lightest}}
              button={{outlined: false, text: 'Handbook'}}
              modal={{closeOnBlur: true, showClose: false, closeOnEsc: true}}
              disabled={document.body?.classList.contains('is-tutorial')}
              contentStyle={styles.handbookModal}>
              <iframe
                src="https://storage.googleapis.com/pf-bucket-default-prod/tutorial/PF_Bridge_Training_Doc.pdf"
                frameBorder="0"
                width="100%"
                height="100%"
                allowFullScreen={true}
                mozallowfullscreen={'true'}
                webkitallowfullscreen={'true'}
              />
            </PfModal>
          </Content>
          {/* <Content>
            <Button
              color="link"
              data-testid="view-tutorial-button"
              style={styles.sideMenuItem}
              onClick={() => {
                window.open('https://www.pfsbc.com/help/learning-center');
              }}>
              Learning Center{' '}
              <Icon
                name={IconNames.MediaVideo}
                style={styles.mLeft}
                color={Theme.colorPalette.lightest}
              />
            </Button>
          </Content> */}
        </div>
      </div>
    </div>
  );
});

const styles = {
  listItem: {
    position: 'relative',
    height: '36px',
    width: '100%',
    display: 'block',
  },
  listItemLink: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  logo: {
    borderRadius: '50%',
    marginBottom: 2,
    overflow: 'hidden',
  },
  sideMenuContainer: {
    flex: 0,
    transition: 'all .25s',
    backgroundColor: 'transparent',
    height: '-webkit-fill-available',
    overflow: 'hidden',
  },
  content: {
    marginBottom: 0,
  },
  sideMenuCard: {
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'stretch',
    display: 'flex',
  },
  sideMenuItem: {
    margin: '0.5em 0.75em',
    minWidth: '13em',
  },
  handbookModal: {
    padding: 0,
    width: '100%',
    height: '100%',
  },
  handbookButton: {
    backgroundColor: Theme.colorPalette.teal,
  },
  tutorialStyles: {
    zIndex: 501,
    width: '100%',
    height: '100%',
    display: 'block',
    position: 'absolute',
    background: 'white',
    boxShadow: 'rgb(255, 255, 255) 0px 0px 5px 5px',
  },
  sideMenuContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    flexGrow: 1,
  },
  mLeft: {
    marginLeft: 10,
  },
  grow: {
    flexGrow: 1,
  },
  name: {
    width: 'auto',
    marginLeft: '10px',
    flex: 1,
  },
};
