// @flow
import React from 'react';
import type {Node} from 'react';
import {Palette} from 'palette';

type PropsType = {
  count: number,
};

export const NavCounter = ({count}: PropsType): Node =>
  count > 0 ? <span style={styles.counter}>{count}</span> : null;

const styles = {
  counter: {
    backgroundColor: Palette.BAD,
    borderRadius: '12px',
    display: 'inline-block',
    padding: '0 7px',
    height: '24px',
    textAlign: 'center',
    lineHeight: '20px',
    fontSize: '12px',
    color: 'white',
    border: '2px solid white',
    boxShadow: '0px 0px 5px rgb(10 10 10 / 20%)',
  },
};
