// @flow

export const NAVIGATION_NAMES = {
  activity: 'Activity',
  announcements: 'Announcements',
  conversation: 'Conversation',
  conversations: 'My Conversations',
  detailEvent: 'Detail Event',
  dashboard: 'Dashboard',
  documentation: 'Documentation',
  groupDetail: 'Group Detail',
  groups: 'Groups',
  memberDetail: 'Member Detail',
  memberDetailGeneralTab: 'Member Detail',
  memberDetailActivityTab: 'Activity',
  memberDetailHabitScheduleTab: 'Habit Schedule',
  memberDetailSessionDocTab: 'Session Documentation',
  members: 'Members',
  newEvent: 'New Event',
  calendar: 'My Calendar',
  staff: 'Staff',
  staffDetail: 'Staff Detail',
  stories: 'Stories',
  storyNew: 'New Story',
  storyEdit: 'Edit Story',
  resources: 'Resources',
  resourcesAll: 'All Resources',
  savedResources: 'Saved Resources',
};
