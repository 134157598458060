import React from 'react';
import {inject, observer} from 'mobx-react';
import {Field, Control} from 'react-bulma-components/lib/components/form';
import Progress from 'react-bulma-components/lib/components/progress';

import Input from '../../tutorial/input';
import SearchResults from './SearchResults';

import {toLegacySearchResults} from 'utils/toLegacySearchResults';

import {BASE_Z_INDEX} from './constants';

const getNoResultsMessage = (searchTerm): Array<string> => [
  `No results found for "${searchTerm}"`,
];

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.searchDelay = null;
  }
  state = {
    searchInput: '',
  };

  _closeSearch = () => {
    this.setState({
      searchTerm: '',
      searchResults: null,
      searchLoading: false,
    });
  };

  _searchByTerm = (event) => {
    this.setState(
      {
        searchInput: event.target.value,
        searchLoading: true,
      },
      () => {
        let term = this.state.searchInput;
        clearTimeout(this.searchDelay);
        if (term.length > 2) {
          this.searchDelay = setTimeout(async () => {
            if (term !== this.state.searchTerm) {
              this.setState({searchTerm: term}, () => this._search());
            } else {
              this.setState({searchLoading: false});
            }
          }, 1000);
        } else {
          this.setState({
            searchTerm: '',
            searchResults: null,
            searchLoading: false,
          });
        }
      },
    );
  };

  async _search() {
    const {searchTerm} = this.state;

    const response = await this.props.organizationStore.search({
      searchTerm,
      queryBy: ['members'],
    });

    if (response.isSuccess) {
      // TODO: Remove legacy handling as part of PAT-1777
      const searchResults =
        response.data.members.length === 0
          ? getNoResultsMessage(searchTerm)
          : response.data.members.map(toLegacySearchResults);

      this.setState({
        searchResults,
        searchLoading: false,
      });
    }
  }

  render() {
    return (
      <div style={styles.container}>
        {this.state.searchResults ? (
          <div
            className={'blocker'}
            style={styles.blocker}
            onClick={() => this._closeSearch()}
          />
        ) : null}
        <div style={styles.searchFieldContainer}>
          <Field>
            <Control>
              <Input
                type="search"
                id="search-input"
                key={this.props.meStore.searchBoxTally}
                autoComplete="search-input"
                placeholder="Search for members by name or email"
                onChange={(e) => this._searchByTerm(e)}
                style={styles.searchField}
                value={this.state.searchInput}
              />
            </Control>
          </Field>
          {this.state.searchLoading ? (
            <Progress size="small" max={100} style={styles.progress} />
          ) : null}
          {this.state.searchResults ? (
            <SearchResults
              results={this.state.searchResults}
              closeSearch={this._closeSearch}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
  },
  blocker: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: BASE_Z_INDEX,
  },
  searchFieldContainer: {
    margin: '8px',
    width: '100%',
    position: 'relative',
    zIndex: BASE_Z_INDEX + 1,
  },
  searchField: {
    minWidth: '25vw',
  },
  progress: {
    marginTop: 50,
    position: 'absolute',
    width: 'calc(100% - 16px)',
  },
};

export default inject('meStore', 'organizationStore')(observer(SearchBox));
