/**
 * @param {array} array
 * @param {string} criteria
 */
export const sortBy = (array, criteria) => {
  const target = criteria.split('.');

  const getVal = (val) => {
    return target.reduce((a, c) => {
      return a[c];
    }, val);
  };

  return array.slice().sort((x, y) => {
    return getVal(x) > getVal(y) ? 1 : -1;
  });
};

export const getTimestampFromPubnubTimetoken = (timetoken) => {
  //Pubnub timetokens are 17-digit precision unix time
  //Shift the decimal 4 digits to the left and drop the remainder to get a timestamp
  return Math.floor(timetoken / 1e4);
};

export const getPubnubTimetokenFromTimestamp = (timestamp) => {
  //Pubnub timetokens are 17-digit precision unix time
  //Shift the decimal 4 digits to the right
  return Math.floor(timestamp * 1e4);
};

export const getIdFromPubnubChannelName = (channelName) => {
  return channelName.split('.')[1];
};

export const pluralize = (term, count) => (count === 1 ? term : `${term}s`);

export const scrollTo = (element, to, duration) => {
  const start = element.scrollTop;
  const change = to - start;
  let currentTime = 0;
  const increment = 20;

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) {
      return (c / 2) * t * t + b;
    }
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

export const getInitials = (string) =>
  string
    .split(' ')
    .map((part) => part.charAt(0))
    .join('');
