import {action, decorate, observable} from 'mobx';
import {Api} from '@wellstone-solutions/common';
import {defaultPageSize} from '../utils/Paginator';
import {getPubnubTimetokenFromTimestamp} from '../utils/Utils';

class ChannelStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  // API CALL for page of channels
  loadChannels = async (isRefresh, isUnread) => {
    if (isRefresh) {
      this.channels = [];
    }
    const params = {limit: defaultPageSize, offset: this.channels.length};
    if (isUnread) {
      params.unread = 1;
    }

    try {
      const response = await Api.Instance.current().get('/messaging/channels', {
        params,
      });

      this.channels = this.channels.concat(
        response.data.channels.filter(
          (value) => !this.channels.find((item) => item.id === value.id),
        ),
      );
    } catch (e) {}
  };

  // API for single channel
  fetchChannel = async (channelId) => {
    const response = await Api.Instance.current().get(
      '/messaging/channels/' + channelId,
    );
    return response.data;
  };

  newChannelActivity = async (channelName) => {
    const channelID = channelName.split('.')[1];
    await this.bumpOrAddChannelToArray(channelID, 'channels');
    await this.bumpOrAddChannelToArray(channelID, 'unreadChannels');
  };

  bumpOrAddChannelToArray = async (channelID, arrName) => {
    let channel = this[arrName].find((c) => c.id === channelID);
    if (!channel) {
      channel = await this.fetchChannel(channelID);
      if (arrName === 'unreadChannels') {
        channel.messageCount = 0;
      }
    }

    this[arrName] = [
      channel,
      ...this[arrName].filter((c) => c.id !== channelID),
    ];
  };

  getChannelByUser = async (user, forceFetch) => {
    let channel = null;
    if (user) {
      this.channels.forEach((c) => {
        if (c.members) {
          c.members.forEach((member) => {
            if (member.id === user) {
              channel = c;
            }
          });
        }
      });

      // Hit the API to get or create the channel for the member
      if (!channel && forceFetch) {
        const response = await Api.Instance.current().post(
          '/messaging/channels',
          {
            members: [{id: user}],
          },
        );

        if (response.isSuccess) {
          channel = response.data;
        }
      }
    }

    return channel;
  };

  getChannelById = async (channelId) => {
    let channel = null;
    if (channelId) {
      this.channels.forEach((c) => {
        if (c.id === channelId) {
          channel = c;
        }
      });
    }

    if (!channel) {
      channel = await this.fetchChannel(channelId);
    }

    return channel;
  };

  updateLocalChannelData = (channelId, channelData) => {
    let channel = this.channels.find((c) => c.id === channelId);
    if (channel) {
      channel.data = {...channel.data, ...channelData};
    }
  };

  updateChannelData = async (channel, channelData) => {
    if (this.rootStore.stores.tutorialStore.isActive) {
      return;
    }

    const params = {
      data: channelData,
    };
    try {
      const response = await Api.Instance.current().post(
        '/messaging/channels/' + channel.id,
        params,
      );
      this.updateLocalChannelData(channel.id, channelData);
      return response;
    } catch (e) {}
  };

  getUnreadChannels = async () => {
    const params = {unread: 1};
    try {
      const response = await Api.Instance.current().get('/messaging/channels', {
        params,
      });
      this.unreadChannels = response.data.channels;
      this.getNewMessageCountsForChannels(this.unreadChannels);
    } catch (e) {}
  };

  findUnreadChannel = (name) => {
    let index;
    this.unreadChannels.forEach((c, i) => {
      index = c.name === name ? i : index;
    });
    return index;
  };

  clearUnreadChannel = (channel) => {
    const index = this.findUnreadChannel(channel.name);
    if (index > -1) {
      this.unreadChannels.splice(index, 1);
      this.setTotalMessages();
    }
  };

  addCountToUnreadChannel = (channelName) => {
    const index = this.findUnreadChannel(channelName);
    const unreadChannel = this.unreadChannels[index];
    if (unreadChannel) {
      unreadChannel.messageCount = (unreadChannel.messageCount ?? 0) + 1;
    }
    this.setTotalMessages();
  };

  setTotalMessages = () => {
    const sum = (arr) => {
      return arr.reduce((a, b) => a + (b.messageCount || 0), 0);
    };
    this.unreadMessagesTotal = sum(this.unreadChannels);
  };

  getNewMessageCountsForChannels = (urChannels) => {
    const {pubnubStore, meStore} = this.rootStore.stores;
    const channels = [];
    const timetokens = [];
    urChannels.forEach((channel) => {
      // If the user has viewed the channel set the timetoken to that viewed date
      // Otherwise set it to the beginning of time
      let timetoken =
        channel.data.last_viewed && channel.data.last_viewed[meStore.me.id]
          ? getPubnubTimetokenFromTimestamp(
              Date.parse(channel.data.last_viewed[meStore.me.id]),
            )
          : getPubnubTimetokenFromTimestamp(1);

      timetoken = timetoken === 0 ? 1 : timetoken;
      timetokens.push(timetoken);
      channels.push(channel.name);
    });
    pubnubStore.getMessageCounts(channels, timetokens, (results) => {
      if (results) {
        Object.keys(results.channels).forEach((ch) => {
          const index = this.findUnreadChannel(ch);
          this.unreadChannels[index].messageCount = results.channels[ch];
        });
      }
      this.setTotalMessages();
    });
  };

  channels = [];
  unreadChannels = [];
}

decorate(ChannelStore, {
  channels: observable,
  unreadMessagesTotal: observable,
  unreadChannels: observable,
  newChannelActivity: action,
  setTotalMessages: action,
});

export default ChannelStore;
