// @flow
import React from 'react';
import type {Node} from 'react';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {
  Box,
  DialogTitle,
  DialogContent,
  Divider,
  MarkdownViewer,
  Typography,
  Stack,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useContactMethods} from '../../hooks';
import {ContactMethod} from '../ContactMethod';

type PropsType = {
  resource: UIResourceType,
};

export const ResourceDetailView = ({resource}: PropsType): Node => {
  const {contactMethods} = useContactMethods(resource);
  return (
    <Box>
      <DialogTitle>
        <Typography sx={styles.title}>{resource.title}</Typography>
        {!!resource.subtitle && (
          <Typography sx={styles.subtitle}>{resource.subtitle}</Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <MarkdownViewer value={resource.description} />
        </Box>

        {!!resource.dateTimeInformation && (
          <Typography sx={styles.dateTimeText}>
            {resource.dateTimeInformation}
          </Typography>
        )}

        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack sx={styles.contactMethods} spacing={2} divider={<Divider />}>
            {contactMethods.map(({iconName, text, href}, index) => (
              <ContactMethod
                key={index}
                iconName={iconName}
                text={text}
                href={href}
              />
            ))}
          </Stack>
        </Stack>
      </DialogContent>
    </Box>
  );
};

const styles = {
  title: {
    color: Theme.colorPalette.onSurface,
    fontSize: 22,
    lineHeight: '24px',
    marginBottom: 1,
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: Theme.colorPalette.onSurface,
  },
  dateTimeText: {
    fontSize: 14,
    color: Theme.colorPalette.dark,
    marginBottom: 2,
  },
  contactMethods: {
    backgroundColor: Theme.colorPalette.secondary4,
    padding: 2,
    borderRadius: 3,

    width: {
      xs: '100%',
      sm: '60%',
    },
  },
};
