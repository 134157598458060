// React
import React from 'react';

// Bulma
import List from 'react-bulma-components/lib/components/list';
import Tag from 'react-bulma-components/lib/components/tag';

// WS
import {withRouter} from 'components/withRouter';
import {getAdminRole} from '../../../utils/Forms';
import Button from '../../tutorial/button';

import {BASE_Z_INDEX} from './constants';

class SearchResults extends React.Component {
  state = {
    show: false,
    rowStyle: {
      color: '',
      backgroundColor: '',
    },
  };

  _navigate = async (result) => {
    let role = result.role === 'patient' ? 'members' : 'staff';
    let target = '/' + role + '/' + result.id;
    // window.location.assign(target);
    // doesn't refresh if already on detail path
    this.props.router.navigate(target);
  };

  render() {
    return (
      <div style={styles.searchResults}>
        <List style={styles.list}>
          {this.props.results.map((result, r) =>
            typeof result === 'string' ? (
              <List.Item key={r}>
                <span>{result}</span>
              </List.Item>
            ) : (
              <List.Item key={r}>
                <Button
                  id={`button-${result.id}`}
                  color="info"
                  inverted={true}
                  tutorialfunction={this.props.closeSearch}
                  onClick={() => this._navigate(result)}
                  style={styles.buttonRow}>
                  <span>{result.user.name}</span>
                  {result.role !== 'patient' ? (
                    <Tag color="info">{getAdminRole(result.role).name}</Tag>
                  ) : null}
                </Button>
              </List.Item>
            ),
          )}
        </List>
      </div>
    );
  }
}

const styles = {
  list: {
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  searchResults: {
    marginTop: 46,
    position: 'absolute',
    width: 'calc(100% - 16px)',
    zIndex: BASE_Z_INDEX + 1,
  },
  buttonRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
};

export default withRouter(SearchResults);
