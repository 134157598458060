// @flow
import React from 'react';
import type {Node} from 'react';
import {HabitSchedule} from 'modules/habits';

type PropsType = {};

export const HabitScheduleTabContent = ({}: PropsType): Node => {
  return <HabitSchedule />;
};
