// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {BridgeEventSources, Events} from '@wellstone-solutions/common';
import {Stack} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {hasFlag, TMP_PEERS_DOCUMENTATION} from 'constants/FeatureFlags';
import {MemberList} from './MemberList';
import {NavTabs} from 'modules/navigation';
import {Outlet} from 'react-router-dom';

export const MemberRoot = (): Node => {
  const {appUIStore, eventStore, meStore} = useStores();

  useEventSource(BridgeEventSources.MEMBERS_PAGE);

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: 'Members',
        url: '/members',
        active: true,
      },
    ]);

    eventStore.addEvent(Events.VIEWED_MEMBER_LIST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUIStore]);

  // Remove TMP_PEER_DOCUMENTATION feature flag, see PAT-3502
  if (!hasFlag(TMP_PEERS_DOCUMENTATION, meStore.features)) {
    return <MemberList />;
  }

  const tabs = [
    {
      label: 'Members',
      value: 'members',
      to: '/members',
    },
    {
      label: 'Session Documentation',
      value: 'documentation',
      to: '/members/documentation',
    },
  ];

  return (
    <>
      <Stack mb={4} sx={{borderBottom: 1, borderColor: 'divider'}}>
        <NavTabs tabs={tabs} />
      </Stack>
      <Outlet />
    </>
  );
};
