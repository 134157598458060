// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {Navigate} from 'react-router-dom';
import {Storage} from '@wellstone-solutions/web';
import AppUpdater from 'components/AppUpdater';
import {NotFound} from 'components/NotFound';
import {useStores} from 'hooks/useStores';

type PropsType = {
  children: Node,
  isProtected?: boolean,
  routes?: mixed,
  allowedRoles?: Array<string>,
  isDisabled?: boolean,
  ...
};

export const RouteElement = ({
  children,
  isProtected = false,
  allowedRoles = [],
  isDisabled = false,
}: PropsType): Node => {
  const [lastViewed, setLastViewed] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const {meStore} = useStores();

  useEffect(() => {
    const lv = Storage.getItem('last_viewed');
    setLastViewed(lv);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return null;
  }

  if (lastViewed) {
    return <AppUpdater lastViewed={lastViewed} />;
  }

  if (isProtected && !meStore.isAuthenticated()) {
    return <Navigate to="/auth/login" />;
  }

  if (isDisabled) {
    return <Navigate to="/" />;
  }

  return allowedRoles.length === 0 || meStore.isAllowedRole(allowedRoles) ? (
    children
  ) : (
    <NotFound />
  );
};
