// @flow
// React
import React, {useState} from 'react';
import type {Node} from 'react';

// Bulma
import BDropdown from 'react-bulma-components/lib/components/dropdown';

// WS
import {Box, Stack, Typography} from '@wellstone-solutions/web';
import Dropdown from 'components/tutorial/dropdown';
import PfLoader from 'components/shared/PfLoader';
import {Palette} from 'palette';
import {PfScatterChart} from './ScatterChart';
import {Tags} from '@wellstone-solutions/common';

type PropsType = {
  aggregator: string,
  checkinData: Array<{
    name: string,
    color: string,
    data: Array<{
      date?: string,
      index: number,
      value: number,
      value_userlist: Array<string>,
    }>,
  }>,
  initialFilter?:
    | 'PAWS'
    | 'AW'
    | 'Physical'
    | 'Behavioral'
    | 'Safety'
    | 'General'
    | 'None',
  showDetailsModal?: boolean,
};

export const PfCheckinChart = ({
  aggregator,
  checkinData,
  initialFilter = 'General',
  showDetailsModal,
}: PropsType): Node => {
  const [selectedFilterName, setSelectedFilterName] = useState(initialFilter);

  const filters = [{name: 'None', type: null, key: null}, ...Tags];

  const _getActiveFilter = () =>
    // $FlowIgnore
    filters.find((filter) => filter.name === selectedFilterName);

  const _handleFilterCheckins = (name) => {
    setSelectedFilterName(name);
  };

  if (!checkinData) {
    return <PfLoader />;
  }
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{marginBottom: 4}}>
        <Typography sx={styles.viewBy}>Filtered By</Typography>
        <Box>
          <Dropdown
            value={selectedFilterName}
            onChange={_handleFilterCheckins}
            id="member-checkins-filter-button">
            {filters.map((filter) => (
              <BDropdown.Item key={filter.key} value={filter.name}>
                {filter.name}
              </BDropdown.Item>
            ))}
          </Dropdown>
        </Box>
      </Stack>
      <Box>
        <PfScatterChart
          size={{height: 400, width: '95%'}}
          filterData={_getActiveFilter()}
          scatterData={checkinData}
          x={{key: aggregator, type: 'category'}}
          y={{key: 'index', type: 'number'}}
          showDetailsModal={showDetailsModal ?? true}
        />
      </Box>
    </Stack>
  );
};

const styles = {
  viewBy: {
    marginRight: 2,
    fontWeight: 'bold',
    color: Palette.INFO,
  },
};
