// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import type {UIResourceAnswerType} from '@wellstone-solutions/common';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {Events, Theme} from '@wellstone-solutions/common';
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  Icon,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {PfButton} from 'components/shared/PfButton';
import {useStores} from 'hooks/useStores';
import {Actions} from 'constants/Messages';
import {hasFlag, RESOURCES_V2} from 'constants/FeatureFlags';
import {showAlert, ALERT_TYPES} from 'utils/showAlert';
import {ShareList} from './ShareList';
import {SelectedChips} from './SelectedChips';
import {SearchInput} from './SearchInput';
import {useShareListDataloader} from '../../../dataloaders';

type PropsType = {
  resource: UIResourceAnswerType | UIResourceType,
  onClose: () => void,
};

export const ShareView = ({resource, onClose}: PropsType): Node => {
  const {channelStore, eventStore, pubnubStore, meStore} = useStores();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isSharing, setIsSharing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const {isLoading, data, fetchData} = useShareListDataloader();

  const handleSearch = async (value: string) => {
    setSearchTerm(value);
    setPageNumber(1);
    await fetchData({searchTerm: value, pageNumber: 1});
  };

  const handleLoadMore = async () => {
    const nextPage = pageNumber + 1;
    setPageNumber(nextPage);
    await fetchData({searchTerm, pageNumber: nextPage, appendResults: true});
  };

  const handleShare = async () => {
    setIsSharing(true);
    let hasError = false;

    const action = hasFlag(RESOURCES_V2, meStore.features)
      ? Actions.SHARED_RESOURCE
      : Actions.SHARED_RESOURCE_ANSWER;

    await Promise.all(
      selectedMembers.map(async (member) => {
        const channel = await channelStore.getChannelByUser(
          member.userId,
          true,
        );
        const message = {
          channel: channel.name,
          data: {
            type: 'message',
            action,
            content: JSON.stringify(resource),
            name: meStore.me.name,
            author: meStore.me.id,
          },
        };
        const {status} = await pubnubStore.publish(channel.name, message);
        if (status.error) {
          hasError = true;
        }
      }),
    );

    onClose();
    if (hasError) {
      showAlert(
        'Failed to share resource.  Please try again.',
        ALERT_TYPES.ERROR,
      );
    } else {
      showAlert('Resource shared!');
      eventStore.addEvent(Events.USER_SHARED_RESOURCE_FROM_RESOURCES, {
        action: Actions.SHARED_RESOURCE_ANSWER,
        id: resource.id,
      });
    }
    setIsSharing(false);
  };

  return (
    <Box>
      <DialogTitle>
        <Stack direction="row">
          <Box sx={styles.icon}>
            <Icon
              name={IconNames.SendDiagonalSolid}
              size={52}
              color={Theme.colorPalette.primary2}
            />
          </Box>
          <Box>
            <Typography sx={styles.title}>Share:</Typography>
            <Typography sx={styles.subtitle}>{resource.title}</Typography>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <SearchInput onSearch={handleSearch} />

        <SelectedChips
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
        />

        <ShareList
          members={data.members}
          hasNextPage={data.hasNextPage}
          onLoadMore={handleLoadMore}
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
          isLoading={isLoading}
        />
      </DialogContent>
      <DialogActions sx={styles.actionsWrapper}>
        <Button onClick={onClose} color="primary" sx={styles.buttonCancel}>
          Cancel
        </Button>

        <PfButton
          color="primary"
          onClick={handleShare}
          loading={isSharing}
          isDisabled={selectedMembers.length === 0}
          style={styles.buttonSend}>
          Send
        </PfButton>
      </DialogActions>
    </Box>
  );
};

const styles = {
  title: {
    color: Theme.colorPalette.onSurface,
    fontSize: 22,
    lineHeight: '24px',
    marginBottom: 0.5,
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: Theme.colorPalette.onSurface,
    marginTop: 0,
  },
  icon: {
    marginRight: 0.5,
    rotate: '-15deg',
  },
  actionsWrapper: {
    p: 2,
    pt: 0,
  },
  buttonCancel: {
    textTransform: 'none',
    marginRight: 2,
    fontWeight: 700,
  },
  buttonSend: {
    minWidth: '150px',
  },
};
