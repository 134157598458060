import React from 'react';
import type {Node} from 'react';
import {Box, Progress, ProgressTypes} from '@wellstone-solutions/web';

export const LoadingIndicator = ({
  stylesOverride,
}: {
  stylesOverride: {[string]: any},
}): Node => (
  <Box
    sx={[
      {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        marginBottom: 4,
      },
      stylesOverride,
    ]}>
    <Progress color="secondary" progressType={ProgressTypes.circular} />
  </Box>
);
