// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {ResourceGrid} from '../ResourceGrid';
import {useResourceGridDataloader} from '../../dataloaders';
import {useResourcesContext} from '../../contexts';

export const AllResources = (): Node => {
  const {searchTerm} = useResourcesContext();

  const {
    isLoading,
    resources,
    totalCount,
    currentPage,
    pageSize,
    filters,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
  } = useResourceGridDataloader();

  useEffect(() => {
    if (searchTerm !== filters.query) {
      onFilterChange({query: searchTerm});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <ResourceGrid
      isLoading={isLoading}
      resources={resources}
      totalCount={totalCount}
      currentPage={currentPage}
      pageSize={pageSize}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    />
  );
};
