// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Events, Theme} from '@wellstone-solutions/common';
import {Icon, IconNames, Stack, Storage} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {PfButton} from 'components/shared/PfButton';
import {showAlert, ALERT_TYPES} from 'utils/showAlert';
import type {UISessionDocumentType} from '../../model/types';
import {
  DOCUMENT_STATUSES,
  HIDE_DOCUMENT_FINALIZE_CONFIRM_STORAGE_KEY,
} from 'modules/documentation/constants';
import type {UIIntegrationType} from '@wellstone-solutions/common';

type PropsType = {
  documentation: UISessionDocumentType,
  ehrIntegration?: UIIntegrationType,
  togglePreviewMode: (value: boolean) => void,
  loadDocumentation: (documentation: UISessionDocumentType) => void,
};

export const PreviewActionButtons = ({
  documentation,
  ehrIntegration,
  togglePreviewMode,
  loadDocumentation,
}: PropsType): Node => {
  const theme = useTheme();
  const smallBreakPoint = useMediaQuery(theme.breakpoints.down('sm'));
  const {sessionDocumentStore, eventStore} = useStores();
  const [isFinalizing, setIsFinalizing] = useState(false);

  // For EHR Integration, button label is Save and submits to EHR
  const isEHREnabled = !!ehrIntegration;
  const saveButtonLabel = isEHREnabled ? 'Save' : 'Finalize';
  const disableSaveButton =
    isEHREnabled &&
    (documentation.member.integrations?.length === 0 ||
      documentation.staff.integrations?.length === 0);
  const isEHRUpdating = !!documentation.integrationRelationship
    ?.externalRelationshipId;

  const finalizeDoc = async (hideFinalizeConfirm?: boolean) => {
    setIsFinalizing(true);

    if (hideFinalizeConfirm) {
      Storage.setItem(HIDE_DOCUMENT_FINALIZE_CONFIRM_STORAGE_KEY, true);
    }

    const response = await sessionDocumentStore.updateSessionDocStatus(
      documentation?.id,
      documentation,
      DOCUMENT_STATUSES.completed,
    );

    if (response.isSuccess) {
      eventStore.addEvent(Events.FINALIZED_SESSION_DOC, {
        session_document_id: documentation.id,
      });
      loadDocumentation(response.data);
    } else {
      setIsFinalizing(false);
      showAlert(
        'Failed to save the document. Please try again.',
        ALERT_TYPES.ERROR,
      );
    }
  };

  const saveEHRDoc = async () => {
    setIsFinalizing(true);

    const response = isEHRUpdating
      ? await sessionDocumentStore.updateExternalEHRDocument(documentation.id)
      : await sessionDocumentStore.createExternalEHRDocument(documentation.id);

    if (response.isSuccess) {
      setIsFinalizing(false);
      // Should we have an analytic event when successful?
      loadDocumentation(response.data);
    } else {
      setIsFinalizing(false);
      showAlert(
        'Failed to submit the document the document. Please try again.',
        ALERT_TYPES.ERROR,
      );
      loadDocumentation(response.data);
    }
    // need to fetch document either way to get the updated status
    // loadDocumentation(response.data);
  };

  const onSaveMethod = isEHREnabled ? saveEHRDoc : finalizeDoc;

  const actionButtonStyles = smallBreakPoint
    ? styles.actionButtonSmall
    : styles.actionButton;

  return (
    <Stack
      direction="row"
      sx={{
        height: Theme.spacing.monster,
        mt: 4,
      }}>
      <PfButton
        outlined={true}
        inverted={true}
        style={{
          ...styles.leftButton,
          ...styles.publishButton,
        }}
        color="primary"
        data-testid="action-button-cancel-preview"
        onClick={() => togglePreviewMode(false)}>
        <Icon name={IconNames.ArrowLeft} color="primary" size={18} />
        Back to Edit
      </PfButton>
      <PfButton
        color="primary"
        data-testid="action-button-publish"
        isDisabled={isFinalizing || disableSaveButton}
        loading={isFinalizing}
        onClick={onSaveMethod}
        style={actionButtonStyles}>
        {saveButtonLabel}
      </PfButton>
    </Stack>
  );
};

const styles = {
  leftButton: {
    marginRight: '32px',
  },
  publishButton: {
    borderColor: Theme.colorPalette.primary,
    color: Theme.colorPalette.primary,
  },
  actionButton: {
    paddingLeft: Theme.spacing.gap,
    paddingRight: Theme.spacing.gap,
  },
  actionButtonSmall: {
    paddingLeft: Theme.spacing.huge,
    paddingRight: Theme.spacing.huge,
  },
  deleteButton: {
    justifyContent: 'flex-end',
    color: 'black',
  },
  confirmButton: {height: 40, marginLeft: 8},
  icon: {marginRight: 4, strokeWidth: 2},
};
