// @flow
// React
import React from 'react';
import type {ComponentType} from 'react';
import {observer} from 'mobx-react';
// Bulma
import Navbar from 'react-bulma-components/lib/components/navbar';

// WS
import {
  hasFlag,
  HIDE_SEARCH_SITE,
  MAINTENANCE_MODE_BRIDGE,
} from 'constants/FeatureFlags';
import SearchBox from './navbar/SearchBox';
import {useStores} from 'hooks/useStores';
import {Palette} from '../../palette';

import PfBridgeLogo from '../../assets/images/PathfinderBridge_RGB.svg';
import {Icon, IconNames} from '@wellstone-solutions/web';
import {NotificationList} from 'modules/notifications';

type PropsType = {};

export const NavigationBar: ComponentType<PropsType> = observer(() => {
  const {appStore, meStore} = useStores();
  const isAuthenticated =
    meStore.me && meStore.me.hasOwnProperty('id') ? true : false;

  return (
    <Navbar style={styles.navBar} transparent={false} className="contained-nav">
      <Navbar.Brand>
        {isAuthenticated &&
          !appStore.apiMaintenanceMode &&
          !hasFlag(MAINTENANCE_MODE_BRIDGE, meStore.features) && (
            <Navbar.Item>
              <Icon
                className="hidden-desktop"
                onClick={appStore.toggle}
                name={IconNames.Menu}
                color={'white'}
              />
            </Navbar.Item>
          )}
        <Navbar.Item renderAs="a" href="/">
          <img
            alt="Pathfinder.Bridge"
            src={PfBridgeLogo}
            style={styles.bridgeLogo}
          />
        </Navbar.Item>
      </Navbar.Brand>
      {isAuthenticated &&
        !appStore.apiMaintenanceMode &&
        !hasFlag(MAINTENANCE_MODE_BRIDGE, meStore.features) &&
        !hasFlag(HIDE_SEARCH_SITE, meStore.features) && (
          <Navbar.Container position="end" style={styles.authenticatedBar}>
            <SearchBox />
            <NotificationList />
          </Navbar.Container>
        )}
    </Navbar>
  );
});

const styles = {
  navBar: {
    background: Palette.DARK_INFO,
    zIndex: 'auto',
  },
  authenticatedBar: {
    display: 'flex',
    minWidth: '50%',
  },
  bridgeLogo: {
    height: 40,
  },
};
