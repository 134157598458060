// @flow
export const RESOURCE_MODAL_VIEWS = {
  detail: 'detail',
  share: 'share',
};

export const DEFAULT_FILTERS: {
  query?: string,
  collections?: Array<string>,
} = {
  query: '',
  collections: [],
};
