// @flow
import React, {useState, useRef} from 'react';
import type {Node} from 'react';
// TODO replace with import from common libs when available
import InputAdornment from '@mui/material/InputAdornment';
import {TextField, Icon, IconButton, IconNames} from '@wellstone-solutions/web';
import {useResourcesContext} from '../../contexts/ResourcesContext';

type PropsType = {
  label: string,
};

export const ResourceSearch = ({label}: PropsType): Node => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  //eslint-disable-next-linea
  const {setSearchTerm} = useResourcesContext();

  const searchInputRef = useRef(null);

  const showPlaceholder = !isFocused && value.length === 0;

  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      value={value}
      size="normal"
      sx={styles.textField}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setSearchTerm(value);
        }
      }}
      InputLabelProps={
        showPlaceholder ? {shrink: false, sx: styles.placeholder} : {}
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon name={IconNames.Search} />
          </InputAdornment>
        ),
        endAdornment: value.length > 0 && (
          <InputAdornment position="end">
            <IconButton
              aria-label="Clear search"
              icon={IconNames.Xmark}
              onClick={() => {
                setValue('');
                setSearchTerm('');
                searchInputRef.current.focus();
              }}
            />
          </InputAdornment>
        ),
      }}
      inputRef={searchInputRef}
    />
  );
};

const styles = {
  textField: {
    maxWidth: {
      xs: '100%',
      md: '360px',
    },
  },
  placeholder: {
    paddingLeft: '28px',
  },
};
