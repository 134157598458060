// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Tabs, Tab} from '@wellstone-solutions/web';
import {
  useResolvedPath,
  useNavigate,
  useLocation,
  matchPath,
  Link,
} from 'react-router-dom';

type PropsType = {
  tabs: Array<{
    label: string,
    value: string,
    to: string,
    onClick?: () => void,
  }>,
};

export const NavTabs = ({tabs}: PropsType): Node => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const parentPath = useResolvedPath('.').pathname;

  // See if the current path matches any of the tabs
  let currentTab = null;
  // update this to use tab.to instead of tab.value to handle child routes ex resources/saved
  const match = tabs.find((tab) => matchPath(tab.to, pathname) !== null);
  if (match) {
    currentTab = match.value;
  }

  // if the selected tab doesnt exist, redirect to the parent route
  // most likely scenario is they dont have access to that tab.
  if (!currentTab) {
    navigate(parentPath);
    return <></>;
  }

  return (
    <Tabs
      value={currentTab}
      sx={{
        '& .MuiTab-root': {
          textTransform: 'none',
          fontSize: Theme.typography.size.s3,
          color: Theme.colorPalette.primary,
          '&.Mui-selected': {
            color: Theme.colorPalette.secondary,
          },
        },
        '& .MuiTabs-indicator': {
          backgroundColor: Theme.colorPalette.secondary,
        },
      }}>
      {tabs.map((t) => (
        <Tab
          {...t}
          key={t.value}
          label={t.label}
          value={t.value}
          to={t.to}
          component={t.to ? Link : null}
        />
      ))}
    </Tabs>
  );
};
