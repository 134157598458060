// @flow
import React, {useState} from 'react';
import {observer} from 'mobx-react';
import type {ComponentType, Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {
  Button,
  Icon,
  IconButton,
  IconNames,
  List,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type {NotificationType} from '../types';
import {useStores} from 'hooks/useStores';
import {NotificationDialog} from './NotificationDialog';
import {
  CalendarEventNotification,
  SessionDocumentNotification,
} from './Notifications';
import {ActiveCount} from 'components/ActiveCount';

export const NotificationList: ComponentType<{}> = observer((): Node => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const {appNotificationStore} = useStores();

  const openNotifications = (event) =>
    setNotificationAnchorEl(event.currentTarget.parentNode);

  const closeNotificationDialog = () => {
    setNotificationAnchorEl(null);
  };

  const getNotificationTemplate = (
    notification: NotificationType<any>,
    key: number,
  ): Node | void => {
    if (notification.type === 'session_documentation') {
      return (
        <SessionDocumentNotification
          item={notification}
          closeMenu={closeNotificationDialog}
          key={key}
        />
      );
    }
    if (notification.type === 'calendar_event') {
      return (
        <CalendarEventNotification
          item={notification}
          closeMenu={closeNotificationDialog}
          key={key}
        />
      );
    }
  };

  return (
    <>
      <Button onClick={openNotifications}>
        <Icon name={IconNames.Bell} size={32} />
        <ActiveCount
          count={appNotificationStore.count}
          sx={{
            background: Theme.colorPalette.red,
            position: 'absolute',
            left: '35px',
            top: '5px',
          }}
        />
      </Button>

      <NotificationDialog
        anchorEl={notificationAnchorEl}
        onClose={closeNotificationDialog}>
        <Stack direction="row" justifyContent="space-between" sx={{px: 0}}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{px: Theme.spacing.micro}}>
            <Typography variant="h6" sx={{fontWeight: 'bold'}}>
              Notifications
            </Typography>
          </Stack>
          {isMobile && (
            <IconButton
              onClick={closeNotificationDialog}
              icon={IconNames.Xmark}
              iconProps={{name: IconNames.Xmark, style: {strokeWidth: 2}}}
              sx={{mr: 0.2}}
              data-testid="close-dialog"
            />
          )}
        </Stack>
        <List>
          {appNotificationStore.notifications.map(getNotificationTemplate)}
        </List>
      </NotificationDialog>
    </>
  );
});
