// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {Divider, Stack} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import type {IntegrationSearchStatusType} from '../types';
import {useStores} from 'hooks/useStores';
import {LogoStatus} from './LogoStatus';
import {FormInfoStatus} from './FormInfoStatus';
import type {UIExternalRelationshipType} from '@wellstone-solutions/common/dist/models/types';
import {INTEGRATION_STATUSES} from '../constants';

type PropsType = {
  form: any,
  initialStatus?: IntegrationSearchStatusType,
  memberType?: string,
  orgIntegration?: mixed,
  step?: mixed,
  setIntegration?: (externalResponse: any) => void,
};

// This is the light green colored box that contains the EHR logo and the form/info status
export const EHRWrapper = ({
  form,
  initialStatus,
  memberType,
  orgIntegration,
  step,
  setIntegration,
}: PropsType): Node => {
  const {READY, CONNECTED, SEARCHING, ERROR} = INTEGRATION_STATUSES;
  const [status, setStatus] = useState<IntegrationSearchStatusType>(
    initialStatus ?? READY,
  );
  const [ehrData, setEHRData] = useState<UIExternalRelationshipType>({});

  const {organizationStore} = useStores();

  const handleSearch = async () => {
    setStatus(SEARCHING);

    const response = await organizationStore.getExternalIntegration(
      form.values.externalRelationshipId,
      memberType === 'staff', // toggles isStaff query param
    );

    if (response.isSuccess) {
      setStatus(CONNECTED);
      setIntegration?.(response.data);

      setEHRData(response.data);
      const {name, email, birthdate, gender} = response.data;

      form.setFieldValue('name', name);
      form.setFieldValue('email', email);
      form.setFieldValue('birthdate', birthdate);
      form.setFieldValue('gender', gender);
    } else {
      setStatus(ERROR);
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        // minHeight: 185,
        backgroundColor: Theme.colorPalette.secondary4,
        p: 3,
        borderRadius: 4,
      }}>
      {/* LEFT SIDE */}
      <LogoStatus
        form={form}
        orgIntegration={orgIntegration}
        status={status}
        memberType={memberType}
      />
      <Divider orientation="vertical" flexItem sx={styles.divider} />
      {/* RIGHT SIDE */}
      <FormInfoStatus
        form={form}
        handleSearch={handleSearch}
        memberIntegration={ehrData}
        memberType={memberType}
        orgIntegration={orgIntegration}
        status={status}
        setStatus={setStatus}
      />
    </Stack>
  );
};

const styles = {
  button: {
    padding: 0,
    height: 40,
    fontSize: 16,
    marginVertical: 'auto',
    marginLeft: 4,
    marginRight: 4,
    alignSelf: 'center',
    flex: 1,
    backgroundColor: Theme.colorPalette.primary,
    color: Theme.colorPalette.lightest,
    borderRadius: 10,
    textTransform: 'capitalize',
  },
  completed: {
    display: 'inline',
  },
  divider: {
    ml: 2,
    mr: 2,
    backgroundColor: Theme.colorPalette.darkest,
    width: 2,
  },
  input: {backgroundColor: Theme.colorPalette.lightest},
  right: {flex: 2, justifyContent: 'space-around'},
};
