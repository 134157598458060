// @Flow
import React, {useState, useRef} from 'react';
import type {Node} from 'react';
import {Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {ACCEPTED_IMAGE_TYPES} from 'constants/Images';

type PropsType = {
  onFileSelect: (file: any) => void,
  fileName?: string,
  appearanceStyles?: any,
  dragStyles?: any,
  containerStyles?: any,
  imageOnly?: boolean,
  disabled?: boolean,
};

export const FileDragDrop = ({
  onFileSelect,
  fileName,
  appearanceStyles,
  dragStyles,
  containerStyles,
  imageOnly = false,
  disabled = false,
}: PropsType): Node => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const labelStyles = appearanceStyles || styles.labelFileUpload;
  const labelDragStyles = dragStyles || styles.dragActive;

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) {
      return;
    }
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) {
      return;
    }
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileSelect(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (disabled) {
      return;
    }
    if (e.target.files && e.target.files[0]) {
      onFileSelect(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = (e) => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    inputRef.current.click();
  };

  return (
    <div
      id="form-file-upload"
      onDragEnter={handleDrag}
      style={{...styles.formFileUpload, ...containerStyles}}>
      <input
        ref={inputRef}
        type="file"
        accept={imageOnly ? ACCEPTED_IMAGE_TYPES : null}
        id="input-file-upload"
        style={styles.inputFileUpload}
        onChange={handleChange}
        disabled={disabled}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        style={{
          ...(dragActive ? {...labelStyles, ...labelDragStyles} : labelStyles),
          ...(disabled ? styles.labelDisabled : {}),
        }}>
        <div>
          <Typography variant="body2">
            {fileName ? fileName : 'Drag and drop your file here or'}
          </Typography>
          {!disabled && (
            <button style={styles.uploadButton} onClick={onButtonClick}>
              {fileName ? 'Upload a new file' : 'Upload a file'}
            </button>
          )}
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          style={styles.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </div>
  );
};

const styles = {
  formFileUpload: {
    height: '140px',
    width: '100%',
    maxWidth: '100%',
    textAlign: 'center',
    position: 'relative',
  },
  inputFileUpload: {
    display: 'none',
  },
  labelFileUpload: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '2px',
    borderRadius: '6px',
    borderStyle: 'dashed',
    borderColor: '#cbd5e1',
    backgroundColor: '#f8fafc',
    cursor: 'pointer',
    padding: '12px',
  },
  labelDisabled: {
    backgroundColor: Theme.colorPalette.mediumLight,
    cursor: 'not-allowed',
  },
  dragActive: {
    backgroundColor: '#ffffff',
  },
  uploadButton: {
    cursor: 'pointer',
    padding: '0.25rem',
    fontSize: '1rem',
    border: 'none',
    backgroundColor: 'transparent',
  },
  dragFileElement: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '1rem',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
};
