// @flow
import {useState, useEffect, useRef} from 'react';
import {Hooks, Api} from '@wellstone-solutions/common';
import {Resource} from '@wellstone-solutions/common/models/rest';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {DEFAULT_FILTERS} from '../constants';

const {usePaginatedData} = Hooks;

type FilterParamsType = {
  query?: string,
  collections?: Array<string>,
};

type ReturnType = {
  isLoading: boolean,
  resources: Array<UIResourceType>,
  totalCount: number,
  currentPage: number,
  pageSize: number,
  filters: FilterParamsType,
  refetchData: () => void,
  onPageChange: (page: number) => void,
  onPageSizeChange: (pageSize: number) => void,
  onFilterChange: (filters: FilterParamsType) => void,
};

export const useResourceGridDataloader = (): ReturnType => {
  const initialLoad = useRef(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const getActiveFilters = () => {
    const activeFilters = {};
    if (filters.query && filters.query.length > 0) {
      activeFilters.query = filters.query;
    }

    if (filters.collections && filters.collections.length > 0) {
      activeFilters.collections = filters.collections;
    }

    return activeFilters;
  };

  const apiCurrentPage = currentPage + 1;

  const {data, refetch, isLoading} = usePaginatedData({
    url: Resource.routes.search(),
    apiVersion: Api.API_VERSIONS.REST,
    isLimitOffset: false,
    dataTransformer: ({data, total_count}) => ({
      resources: data.map(Resource.toUI),
      totalCount: total_count,
    }),
    currentPage: apiCurrentPage,
    pageSize,
    params: getActiveFilters(),
    sortField: 'title',
    sortOrder: 'asc',
  });

  const refetchData = () => {
    if (refetch) {
      refetch({
        currentPage: apiCurrentPage,
        pageSize,
        params: getActiveFilters(),
      });
    }
  };

  const onPageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setCurrentPage(0);
    setPageSize(newPageSize);
  };

  const onFilterChange = (newFilters: FilterParamsType) => {
    setCurrentPage(0);
    setFilters({
      ...filters,
      ...newFilters,
    });
  };

  useEffect(() => {
    if (initialLoad.current === false) {
      refetchData();
    } else {
      initialLoad.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, filters]); // Ensure all dependencies are correct

  return {
    isLoading,
    resources: data?.resources || [],
    totalCount: data?.totalCount || 0,
    currentPage,
    pageSize,
    filters: filters || DEFAULT_FILTERS,
    refetchData,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
  };
};
