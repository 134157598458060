// @flow
import {createContext, useMemo, useState, useContext} from 'react';
import type {
  UIResourceType,
  UIUserResourceType,
} from '@wellstone-solutions/common/models/rest';

type ContextValueType = {
  resources: Array<UIResourceType>,
  savedResources: Array<UIUserResourceType>,
  setResources: (resources: Array<UIResourceType>) => void,
  setSavedResources: (savedResources: Array<UIUserResourceType>) => void,
  searchTerm: string,
  setSearchTerm: (searchTerm: string) => void,
};

export const ResourcesContext: React$Context<ContextValueType | void> = createContext();

export const ResourcesProvider = ({
  children,
}: {
  children: any,
}): React$Element<any> => {
  const [savedResources, setSavedResources] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [resources, setResources] = useState([]);

  const contextValue = useMemo(
    () => ({
      resources,
      savedResources,
      setResources,
      setSavedResources,
      searchTerm,
      setSearchTerm,
    }),
    [resources, savedResources, searchTerm],
  );

  return (
    <ResourcesContext.Provider value={contextValue}>
      {children}
    </ResourcesContext.Provider>
  );
};

export const useResourcesContext = (): ContextValueType => {
  const context = useContext(ResourcesContext);
  if (context === undefined) {
    throw new Error(
      'use ResourcesContext must be used within a ResourcesProvider',
    );
  }

  return context;
};
