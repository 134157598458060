//@flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {Box, Stack} from '@wellstone-solutions/web';
import {hasFlag, RESOURCES_V2} from 'constants/FeatureFlags';
import {useStores} from 'hooks/useStores';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import {ResourcesProvider} from '../contexts';
import {NavTabs} from 'modules/navigation';
import {ResourceSearch} from './ResourceSearch';

export const ResourcesRoot = (): Node => {
  const {meStore, appUIStore} = useStores();

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: NAVIGATION_NAMES.resources,
        url: '/resources',
        active: true,
      },
    ]);
  }, [appUIStore]);

  if (!hasFlag(RESOURCES_V2, meStore.features)) {
    return <Navigate to="/" />;
  }

  const tabs = [
    {label: 'All Resources', value: 'all', to: '/resources'},
    {label: 'Saved', value: 'saved', to: '/resources/saved'},
  ];

  return (
    <ResourcesProvider>
      <Box sx={styles.fullHeight}>
        <Box sx={styles.header}>
          <Stack
            direction="row"
            alignItems="center"
            sx={styles.searchTabsContainer}>
            <Box sx={styles.searchBox}>
              <ResourceSearch label="Search all resources" />
            </Box>
            <Box sx={styles.tabsBox}>
              <NavTabs tabs={tabs} />
            </Box>
          </Stack>
        </Box>
        <Outlet />
      </Box>
    </ResourcesProvider>
  );
};

const styles = {
  fullHeight: {
    height: 'calc(100% - 98px)',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: 3,
  },
  searchTabsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchBox: {
    marginRight: '8px',
  },
  tabsBox: {
    display: 'flex',
    alignItems: 'center',
  },
};
