// @flow
import React from 'react';
import type {Node} from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Box, Popover, Dialog} from '@wellstone-solutions/web';

type PropsType = {
  anchorEl: HTMLElement | null,
  onClose: () => void,
  children: Node,
};

export const NotificationDialog = ({
  anchorEl,
  onClose,
  children,
}: PropsType): Node => {
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      data-testid={'notification-dialog-mobile'}>
      <Box p={2}>{children}</Box>
    </Dialog>
  ) : (
    <Popover
      data-testid={'notification-dialog-desktop'}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      sx={{
        '& .MuiPopover-paper': {
          borderRadius: '10px',
          width: '400px',
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 2,
        horizontal: 'right',
      }}>
      <Box px={0} pt={2}>
        {children}
      </Box>
    </Popover>
  );
};
