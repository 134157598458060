// @flow
import React from 'react';
import {observer} from 'mobx-react';
import type {ComponentType} from 'react';

import {Theme} from '@wellstone-solutions/common'

import {useStores} from 'hooks/useStores';

type PropsType = {};
export const SiteHeader: ComponentType<PropsType> = observer(() => {
    const {meStore} = useStores();
    const isAuthenticated = meStore.isAuthenticated();
    return (
        (isAuthenticated && 
            <div data-testid="responsive-header" style={style.header}>PATHFINDER RESPONSIVE HEADER</div>
        )
    )
})

const style = {
    header : {
        backgroundColor:'red',
        height: '5em',
        background: Theme.colorPalette.primary,
        zIndex: 'auto',
        color: Theme.colorPalette.onPrimary,
        display:'flex',
        justifyContent:'center',
        alignItems: 'center'
    }
}