// @flow
import React from 'react';
import type {Node} from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Box, Typography} from '@wellstone-solutions/web';
import {Theme, Transforms} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {GCS_BASE_URL, GCS_DEFAULT_BUCKET} from 'constants/CloudStorage';
import type {
  ApiOrganizationType,
  UIOrganizationType,
} from 'modules/organization/types';

export const Branding = (): Node => {
  const theme = useTheme();
  const medBreakPoint: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const {meStore} = useStores();

  const {
    organization: apiOrganization,
  }: {organization: ApiOrganizationType} = meStore.me.membership;

  const uiOrganization: UIOrganizationType = Transforms.toUIObject(
    apiOrganization,
  );

  const {data: orgData} = uiOrganization;

  const defaultLogoUrl = `${GCS_BASE_URL}/${GCS_DEFAULT_BUCKET}/organizations/logos/PathfinderLeaf_RGB.png`;
  const logoUrl = uiOrganization.imageUrl ?? defaultLogoUrl;
  const logoAltText = orgData?.logoAltText ?? uiOrganization.name;
  const hideOrgName = orgData?.hideOrgName ?? false;

  const logoContainerStyle = medBreakPoint
    ? styles.logoContainerMD
    : styles.logoContainerLG;

  return (
    <Box style={logoContainerStyle}>
      <img src={logoUrl} alt={logoAltText} style={styles.logo} />
      {!hideOrgName && (
        <Typography style={styles.orgName}>{uiOrganization.name}</Typography>
      )}
    </Box>
  );
};

const styles = {
  logoContainerLG: {
    textAlign: 'center',
    width: 278,
  },
  logoContainerMD: {
    textAlign: 'center',
    width: 260,
  },
  logo: {
    height: 138,
    objectFit: 'contain',
  },
  orgName: {
    fontSize: '18px',
    fontWeight: 700,
    color: Theme.colorPalette.darkest,
  },
};
