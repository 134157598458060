// @flow
import {observable, action, decorate} from 'mobx';
import {Api} from '@wellstone-solutions/common';
import {AnnouncementStore} from 'modules/announcements/store';
import {AppUIStore} from 'modules/app';
import MeStore from './MeStore';
import PubnubStore from './PubnubStore';
import {ProgramStore} from './ProgramStore';
import NotificationStore from './NotificationStore';
import {AppNotificationStore} from 'modules/notifications/store';
import {MemberStore} from 'modules/member/store';
import MemberDetailStore from './MemberDetailStore';
import MessageStore from './MessageStore';
import AppStore from './AppStore';
import {ResourceStore} from 'modules/resources/store';
import {CalendarStore} from 'modules/calendar/store';
import {DashboardStore} from 'modules/dashboard/store';
import {RBACStore} from 'modules/rbac';
import {StaffStore} from 'modules/staff/store';
import {StoryStore} from 'modules/stories/store';
import ChannelStore from './ChannelStore';
import TutorialStore from './tutorial/TutorialStore';
import OrganizationStore from './OrganizationStore';
import {GroupStore} from 'modules/group/store';
import EventStore from './EventStore';
import {HabitStore} from 'modules/habits/store';
import {SessionDocumentStore} from 'modules/documentation/store';

const STORE_SUFFIX = 'Store';
const STORE_BLACKLIST = ['meStore'];

type StoresType = {
  announcementStore: AnnouncementStore,
  groupStore: any,
  tutorialStore: mixed,
  meStore: mixed,
  memberStore: MemberStore,
  pubnubStore: mixed,
  notificationStore: mixed,
  appNotificationStore: AppNotificationStore,
  messageStore: mixed,
  appStore: mixed,
  resourceStore: ResourceStore,
  calendarStore: CalendarStore,
  dashboardStore: DashboardStore,
  RBACStore: RBACStore,
  staffStore: StaffStore,
  storyStore: StoryStore,
  channelStore: mixed,
  organizationStore: mixed,
  eventStore: mixed,
  appUIStore: mixed,
  memberDetailStore: MemberDetailStore,
  habitStore: HabitStore,
  programStore: ProgramStore,
  sessionDocumentStore: SessionDocumentStore,
};

class RootStore {
  static myInstance: RootStore | null = null;
  stores: StoresType;
  isReady: boolean = false;

  static getInstance(): RootStore | null {
    if (RootStore.myInstance == null) {
      RootStore.myInstance = new RootStore();
    }

    return this.myInstance;
  }

  constructor() {
    this.stores = {
      announcementStore: new AnnouncementStore(this),
      groupStore: new GroupStore(this),
      tutorialStore: new TutorialStore(this),
      meStore: new MeStore(this),
      memberStore: new MemberStore(this),
      pubnubStore: new PubnubStore(this),
      notificationStore: new NotificationStore(this),
      appNotificationStore: new AppNotificationStore(this),
      messageStore: new MessageStore(this),
      appStore: new AppStore(this),
      resourceStore: new ResourceStore(this),
      calendarStore: new CalendarStore(this),
      dashboardStore: new DashboardStore(this),
      RBACStore: new RBACStore(this),
      staffStore: new StaffStore(this),
      storyStore: new StoryStore(this),
      channelStore: new ChannelStore(this),
      organizationStore: new OrganizationStore(this),
      eventStore: new EventStore(this),
      appUIStore: new AppUIStore(this),
      memberDetailStore: new MemberDetailStore(this),
      habitStore: new HabitStore(this),
      programStore: new ProgramStore(this),
      sessionDocumentStore: new SessionDocumentStore(this),
    };
  }

  done(): void {
    this.isReady = true;
  }

  async init(): Promise<mixed> {
    try {
      // $FlowFixMe
      const canLoadApp = await this.stores.meStore.init();

      // Bounce out if we have no token, if it is invalid when we make
      // an api call it will 401 and sign out the user
      if (!canLoadApp) {
        this.done();
        return;
      }

      const initPromises = [];
      for (let prop in this.stores) {
        if (!STORE_BLACKLIST.includes(prop) && prop.endsWith(STORE_SUFFIX)) {
          // $FlowIgnoreMe
          if (this.stores[prop].init) {
            initPromises.push(this.stores[prop]);
          }
        }
      }

      // Initialize stores
      await Promise.all(
        initPromises.map(async (store) => {
          await store.init();
        }),
      );

      // $FlowFixMe
      this.stores.meStore.setTutorial();
    } catch (error) {
      console.log('rootStore:error', error);
      // don't throw an error if its an aborted call
      if (!Api.Instance.current().isAborted(error)) {
        throw error;
      }
    } finally {
      this.done();
    }
  }
}

decorate(RootStore, {
  isReady: observable,
  done: action,
});

export default RootStore;
