// @flow
import React from 'react';
import type {Node} from 'react';
import {Box} from '@wellstone-solutions/web';
import {SessionDocumentList} from 'modules/documentation';
import {useStores} from 'hooks/useStores';
import {AccessControl} from 'modules/rbac';

export const SessionDocTabContent = (): Node => {
  const {meStore, RBACStore} = useStores();

  const showMyDocuments = RBACStore.hasAccess(
    AccessControl.documentation.viewMySessionDocs,
  );

  const showProgramFilter = RBACStore.hasAccess(
    AccessControl.documentation.viewProgramFilter,
  );

  const defaultParams = showMyDocuments
    ? {staff: meStore.activeMembership.id}
    : {};

  const allColumns = [
    'dateOfService',
    'member',
    'staff',
    'sessionType',
    'typeOfService',
    'status',
    'actions',
  ];

  // Include staff when showing all documents
  const columns = showMyDocuments
    ? allColumns.filter((c) => c !== 'staff')
    : allColumns;

  return (
    <Box mb={1}>
      <SessionDocumentList
        title="Session Documentation"
        defaultParams={defaultParams}
        columnNames={columns}
        showProgramFilter={showProgramFilter}
        showMemberFilter={true}
        showStaffFilter={!showMyDocuments}
      />
    </Box>
  );
};
