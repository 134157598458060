// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import type {UIMemberBasicType } from '@wellstone-solutions/common';
import {BridgeEventSources } from '@wellstone-solutions/common';
import {Box, Snackbar} from '@wellstone-solutions/web';
import type {UISessionDocumentType} from 'modules/documentation/model/types';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import {useStores} from 'hooks/useStores';
import {FormContainer} from './SessionDocument/FormContainer';
import {View} from './SessionDocument/View';
import {DOCUMENT_STATUSES} from '../constants';
import {useEventSource} from 'hooks/useEventSource';
import {NotFound} from 'components/NotFound';

type PropTypes = {
  data: {member: UIMemberBasicType, documentation: UISessionDocumentType},
  loadDocumentation: (documentation: UISessionDocumentType) => void,
};

export const DocumentationContainer = ({
  data,
  loadDocumentation,
}: PropTypes): Node => {
  const {appUIStore, eventStore, memberDetailStore, meStore} = useStores();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [showPreviewSnackbar, setShowPreviewSnackbar] = useState(false);
  const {member, documentation} = data;

  useEventSource(BridgeEventSources.DOCUMENTATION_PAGE);

  useEffect(() => {
    
    if (!member) {
      return;
    }

    appUIStore.setBreadcrumbs([
      {
        name: 'Members',
        url: '/members',
      },
      {
        name: member.name,
        url: `/members/${member.id}`,
        active: false,
      },
      {
        name: NAVIGATION_NAMES.documentation,
        url: `/members/${member.id}/documentation`,
        active: true,
      },
    ]);
  }, [eventStore, appUIStore, member, memberDetailStore]);

  if (!documentation) {
    return <NotFound />;
  }

  const togglePreviewMode = (value: boolean) => {
    setIsPreviewing(value);
    if (value) {
      setShowPreviewSnackbar(true);
    }
  };

  const inProgressStatus =
    [
      DOCUMENT_STATUSES.draft,
      DOCUMENT_STATUSES.created,
      DOCUMENT_STATUSES.error,
      DOCUMENT_STATUSES.reverted,
    ].indexOf(documentation.status) >= 0;

  const showReadOnly =
    documentation &&
    (meStore.activeMembership.id !== documentation.staff.id ||
      !inProgressStatus ||
      isPreviewing);

  return (
    <Box sx={styles.container}>
      {showReadOnly ? (
        <View
          documentation={documentation}
          loadDocumentation={loadDocumentation}
          togglePreviewMode={togglePreviewMode}
          isPreviewing={isPreviewing && inProgressStatus}
        />
      ) : (
        <FormContainer
          member={member}
          documentation={documentation}
          loadDocumentation={loadDocumentation}
          togglePreviewMode={togglePreviewMode}
        />
      )}
      <Snackbar
        open={showPreviewSnackbar}
        autoHideDuration={5000}
        title="Preview Generated"
        message="Please review the document before finalizing."
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        onClose={() => setShowPreviewSnackbar(false)}
        severity="warning"
      />
    </Box>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
  },
};
