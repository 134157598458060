// React
import React, {useState, useMemo} from 'react';
import {Palette} from 'palette';

// Third party
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
} from 'recharts';

// Bulma
import Modal from 'react-bulma-components/lib/components/modal';

// WS
import {Checkins} from '@wellstone-solutions/common';
import {MemberList} from './MemberList';

const CustomizedDot = (props) => {
  const {cx, cy, index, fill, height, yAxis, value_userlist, showModal} = props;

  return (
    <circle
      cx={cx}
      cy={cy}
      r={index > 0 ? height / 2 : 0}
      stroke="black"
      strokeWidth={0}
      fill={fill}
      style={styles.cursor}
      onClick={() => showModal(yAxis.name, value_userlist)}
    />
  );
};

export const PfScatterChart = (props) => {
  const {scatterData, filterData, size, x, y} = props;
  const [userIds, setUserIds] = useState([]);

  const parseDomain = () => [
    0,
    Math.max.apply(
      Math,
      scatterData.map((o) => {
        return Math.max.apply(
          Math,
          o.data.map((entry) => {
            return entry.value;
          }),
        );
      }),
    ),
  ];

  const _filterOutCheck = (dataset) => {
    let out = false;
    if (!filterData) {
      return;
    }

    if (filterData.key) {
      out = true;
      const filterTag = filterData.key.toUpperCase();
      const checkin = Checkins.find((item) => item.value === dataset.name) || {
        title: {
          en_US: 'Unknown',
        },
        color: Palette.GREY_2,
        value: 'unknown',
      };
      if (checkin.tags.includes(filterTag)) {
        out = false;
      }
    }
    return out;
  };

  const showModal = async (name, users) => {
    if (props.showDetailsModal === false) {
      return null;
    }

    setUserIds(users);
  };

  const hideModal = () => {
    setUserIds([]);
  };

  const _renderChart = (dataset, domain, range, index) => {
    // TODO : this should be toggled based on data change as well as window resize
    const w = window.innerWidth;
    // (45) rough estimate of necessary ratio to display date labels cleanly
    const dateFontSize = w / dataset.data.length < 45 ? 0 : 10;
    return (
      <ResponsiveContainer key={index} width={size.width} height={60}>
        <ScatterChart
          margin={{
            top: 10,
            right: 0,
            bottom: 0,
            left: 0,
          }}>
          <XAxis
            type={x.type}
            dataKey={x.key}
            interval={0}
            tick={{fontSize: dateFontSize}}
            tickLine={{transform: 'translate(0, -6)'}}
          />
          <YAxis
            type={y.type}
            dataKey={y.key}
            name={dataset.name}
            width={150}
            tick={false}
            tickLine={false}
            axisLine={true}
            label={{
              value: dataset.name.replace('_', ' '),
              position: 'insideRight',
              fill: dataset.color,
              style: styles.axisLabel,
            }}
          />
          <ZAxis type="number" dataKey="value" domain={domain} range={range} />
          <Tooltip
            cursor={{strokeDasharray: '3 3'}}
            wrapperStyle={styles.tooltipWrapper}
            content={<CustomTooltip />}
          />
          <Scatter
            data={dataset.data}
            fill={dataset.color}
            shape={<CustomizedDot showModal={showModal} />}
          />
        </ScatterChart>
      </ResponsiveContainer>
    );
  };

  const domain = useMemo(parseDomain, [scatterData]);
  const range = [16, 225];

  return (
    <div>
      <Modal
        show={!!userIds.length}
        onClose={hideModal}
        className="pfModal"
        closeOnBlur={true}
        closeOnEsc={true}>
        <Modal.Content style={styles.modal}>
          <MemberList userIds={userIds} />
        </Modal.Content>
      </Modal>
      {scatterData.map((dataset, index) => {
        return _filterOutCheck(dataset)
          ? null
          : _renderChart(dataset, domain, range, index);
      })}
    </div>
  );
};

const CustomTooltip = ({active, payload, label}) => {
  if (active && payload[0] && payload[1] && payload[2]) {
    return (
      <div style={styles.tooltipBox}>
        <p style={styles.label}>{`${payload[0].value}`}</p>
        <p style={styles.value}>{`${payload[1].name} : ${payload[2].value}`}</p>
      </div>
    );
  }
  // if no data, do NOT display a tooltip
  return null;
};

const styles = {
  cursor: {cursor: 'pointer'},
  loaderStyle: {
    width: 100,
    height: 100,
    marginTop: 100,
  },
  tooltipWrapper: {
    zIndex: 100,
  },
  tooltipBox: {
    border: '1px solid #ccc',
    backgroundColor: 'white',
    padding: '8px 12px',
  },
  label: {
    fontWeight: 'normal',
  },
  value: {
    fontWeight: 'bold',
  },
  axisLabel: {
    fontWeight: 'bold',
    fontSize: 12,
    marginRight: 5,
  },
  modal: {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 10,
    width: 'auto',
  },
};
