// React
import React from 'react';

// Bulma
import Footer from 'react-bulma-components/lib/components/footer';

// WS
import {Palette} from '../../palette';

class FooterBar extends React.Component {
  state = {};

  render() {
    return (
      <Footer style={styles.footer}>
        <div style={styles.footerContent}>
          <div style={styles.contentLeft}>
            <div style={styles.name}>
              Pathfinder.Bridge {this.props.version}
            </div>
            <div>
              © {new Date().getFullYear()} Copyright{' '}
              <a
                rel="noopener noreferrer"
                href="//www.pfsbc.com"
                target="_blank"
                style={styles.link}>
                Pathfinder Solutions PBC
              </a>
            </div>
            <div>
              For technical assistance,{' '}
              <a style={styles.link} href="mailto:info@pfsbc.com">
                email us
              </a>
              .
            </div>
          </div>
          <div style={styles.contentRight}>
            <a
              rel="noopener noreferrer"
              href="https://www.pfsbc.com/legal-docs/terms-of-use"
              target="_blank"
              style={styles.docLink}>
              Terms of Use
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.pfsbc.com/legal-docs/privacy-policy"
              target="_blank"
              style={styles.docLink}>
              Privacy Policy
            </a>
          </div>
        </div>
      </Footer>
    );
  }
}
const styles = {
  footer: {
    marginTop: 30,
    backgroundColor: Palette.DARK_INFO,
    color: Palette.LIGHT_INFO,
    zIndex: 100,
    position: 'relative',
    paddingBottom: '3rem',
  },
  footerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  contentLeft: {
    borderWidth: 1,
    borderColor: 'white',
    display: 'flex',
    paddingBottom: 10,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  contentRight: {
    borderWidth: 1,
    borderColor: 'white',
    paddingBottom: 10,
    minWidth: 300,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  name: {
    color: Palette.LIGHT_INFO,
    fontWeight: 'bold',
  },
  link: {
    color: Palette.EXCITE_0,
  },
  docLink: {
    color: Palette.EXCITE_0,
  },
};
export default FooterBar;
