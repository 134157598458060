// @flow
// TODO: Legacy handling can be safely removed after PAT-1777 is done
type LegacySearchResultsType = {
  user: {name: string},
  role: string,
  id: string,
};

export const toLegacySearchResults = (member: {
  name: string,
  role: string,
  id: string,
}): LegacySearchResultsType => {
  return {
    user: {name: member.name},
    role: member.role,
    id: member.id,
  };
};
