// @flow
// React
import React from 'react';
import type {Node} from 'react';

import {Routes as SharedRoutes} from 'components/Routes';
import {ResetPasswordModule} from 'components/auth/ResetPassword/ResetPasswordModule';

const ResetPasswordPage = (): Node => (
  <div style={styles.container}>
    <ResetPasswordModule />
  </div>
);
const styles = {
  container: {
    width: '100%',
  },
};

export const routes = [
  {
    path: '*',
    Component: ResetPasswordPage,
    isProtected: false,
  },
];

// $FlowIgnore
const ResetFrame = () => <SharedRoutes routes={routes} />;

export default ResetFrame;
