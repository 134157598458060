// React
import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Third party
import {styled} from '@mui/material/styles';
import {inject, observer} from 'mobx-react';
import {
  Icon,
  IconNames,
  Progress,
  Stack,
  Storage,
  Tooltip,
  Typography,
  Checkbox,
} from '@wellstone-solutions/web';
import {Events, BridgeEventSources} from '@wellstone-solutions/common';

// Bulma
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import {
  Field,
  Control,
  Help,
  Label,
  Input,
} from 'react-bulma-components/lib/components/form';
import Button from 'react-bulma-components/lib/components/button';

// WS
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import RootStore from 'mobx/RootStore';
import {withRouter} from 'components/withRouter';
import {fieldStatus} from 'utils/Forms';
import styles from './LoginModule.module.scss';
import {Palette} from '../../palette';
import {EMAIL_PLACEHOLDER, PASSWORD_PLACEHOLDER} from './constants';

const STORAGE_LOGIN_METHOD_KEY = 'login/preferred-method';
const LOGIN_METHODS = {
  password: 'password',
  code: 'code',
};

const CODE_INFO_TIP =
  'The code is a unique number that is emailed to you each time you login to Bridge.';

const CodeInfoDiv = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 0.5,
}));

class LoginModule extends Component {
  state = {
    email: '',
    password: '',
    didSubmitEmail: false,
    isLoginWithCode: true,
    showLockMessage: false,
    memberships: this.props.meStore.me?.memberships || [],
    loading: false,
    touAgree: true,
  };

  componentDidMount() {
    // retrieve preferred login method from local storage
    const loginMethod = Storage.getItem(STORAGE_LOGIN_METHOD_KEY);
    this.props.eventStore.setEventSource(BridgeEventSources.LOGIN_PAGE);
    // Allows you to direct users to the Login with password flow with the ?method=password query param
    const usePasswordMethod =
      this.props.router.query.get('method') === LOGIN_METHODS.password ||
      loginMethod === LOGIN_METHODS.password;

    this.setState({isLoginWithCode: !usePasswordMethod});
  }

  _goToDashboard = async () => {
    this.props.router.navigate('/dashboard');
  };

  _onOrganizationClick = async (organizationId) => {
    this.setState({loading: true});
    await this._initSelectedOrganization(organizationId);
    this.setState({loading: false});
    this._goToDashboard();
  };

  _initSelectedOrganization = async (organizationId) => {
    this.props.meStore.setActiveOrganization(organizationId);
    await RootStore.getInstance().init();
  };

  _checkForErrors = (field) => {
    const error = fieldStatus(this.state[field], field, true);
    return error.error ? error : false;
  };

  _loginWithPassword = async () => {
    try {
      this.setState({loading: true});
      const {
        memberships,
        response,
      } = await this.props.meStore.signinWithPassword(
        this.state.email,
        this.state.password,
      );

      if (memberships.length > 0) {
        // set preferred login method
        Storage.setItem(STORAGE_LOGIN_METHOD_KEY, LOGIN_METHODS.password);
        // If only one membership, just select that one
        if (memberships.length === 1) {
          await this._initSelectedOrganization(memberships[0].organization.id);
          this.props.eventStore.addEvent(Events.STAFF_LOGGED_IN, {
            loginWithPassword: true,
          });
          this._goToDashboard();
        } else if (memberships.length > 0) {
          this.setState({
            memberships: memberships,
          });
        }
      } else {
        // status 403 means we're locked out
        const errorMessage =
          response.status === 403
            ? response.data.error
            : 'Invalid email or password';

        if (errorMessage.indexOf('lock') >= 0) {
          this.setState({showLockMessage: true});
        } else {
          this.setState({
            passwordError: {
              color: 'danger',
              error: errorMessage,
            },
            loading: false,
          });
        }
      }

      this.setState({loading: false});
    } catch (err) {
      this.setState({
        passwordError: {
          color: 'danger',
          error: 'Invalid email or password',
        },
        loading: false,
      });
    }
  };

  _toggleLogin = () => {
    this.setState({isLoginWithCode: !this.state.isLoginWithCode});
  };

  _getCode = () => {
    const error = this._checkForErrors('email');
    if (!error) {
      try {
        this.props.meStore.requestCode(this.state.email.toLowerCase());
        this.setState({didSubmitEmail: true});
      } catch {}
    } else {
      this.setState({
        emailError: error,
      });
    }
  };

  _reset = () => {
    this.setState({didSubmitEmail: false, email: '', password: ''});
    this.props.router.navigate('/auth/login', {replace: true});
  };

  _submitCode = async () => {
    const error = this._checkForErrors('code');
    if (!error) {
      try {
        this.setState({loading: true});
        const memberships = await this.props.meStore.signin(
          this.state.email.toLowerCase(),
          this.state.code,
        );

        if (memberships.length > 0) {
          // set preferred login method
          Storage.setItem(STORAGE_LOGIN_METHOD_KEY, LOGIN_METHODS.code);
          // If only one membership, just select that one
          if (memberships.length === 1) {
            await this._initSelectedOrganization(
              memberships[0].organization.id,
            );
            this.props.eventStore.addEvent(Events.STAFF_LOGGED_IN, {
              loginWithPassword: false,
            });
            this._goToDashboard();
          } else if (memberships.length > 0) {
            this.setState({
              memberships: memberships || [],
            });
          }
        } else {
          showAlert(
            'You must be a valid Staff Member to log in',
            ALERT_TYPES.ERROR,
          );
          this.setState({code: '', didSubmitEmail: false});
        }

        this.setState({loading: false});
      } catch (err) {
        this.setState({
          codeError: {
            color: 'danger',
            error:
              'The code entered must be perfect. The code you entered may be incorrect or expired',
          },
          loading: false,
        });
      }
    } else {
      this.setState({
        codeError: error,
      });
    }
  };

  _loginStage = () => {
    const showPasswordScreen = !this.state.isLoginWithCode;

    if (
      this.props.meStore.isAuthenticated() &&
      this.state.memberships.length > 0
    ) {
      return (
        <>
          <Field>
            <Label>Organization</Label>
            {this.state.memberships.map((membership, m) => {
              return (
                <Button
                  key={m}
                  fullwidth={true}
                  inverted={false}
                  color="primary"
                  outlined={false}
                  className={styles.membershipButton}
                  disabled={this.state.loading}
                  onClick={() =>
                    this._onOrganizationClick(membership.organization.id)
                  }>
                  {membership.organization.name}
                </Button>
              );
            })}
          </Field>
        </>
      );
    }

    if (this.state.didSubmitEmail) {
      return (
        <div>
          <Field>
            <Label style={{color: Palette.INFO}}>Code</Label>
            <Control>
              <Input
                color={this.state.codeError?.color}
                data-testid="code-input"
                type="text"
                required
                placeholder="6-digit code"
                onChange={(newVal) =>
                  this.setState({code: newVal.target.value})
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this._submitCode();
                  }
                }}
                value={this.state.code}
              />
            </Control>
            {this.state.codeError ? (
              <Help color={this.state.codeError?.color}>
                {this.state.codeError?.error}
              </Help>
            ) : null}
          </Field>
          <div className={styles.panelFooter}>
            <div>
              <Button
                color="primary"
                data-testid="submit-code-button"
                disabled={
                  !this.state.touAgree ||
                  !!this._checkForErrors('code') ||
                  this.state.loading
                }
                onClick={this._submitCode}>
                Submit Code
              </Button>
            </div>
            <div>
              <Button color="primary" inverted onClick={this._reset}>
                Send a new Code
              </Button>
            </div>
          </div>
          {this.state.loading && (
            <Stack direction="row" justifyContent="center">
              <Progress color="primary" className={styles.spinner} />
            </Stack>
          )}
        </div>
      );
    }

    return (
      <div>
        <Field>
          <Label style={{color: Palette.PRIMARY}}>Email</Label>
          <Control>
            <Input
              color={this.state.emailError?.color}
              data-testid="email-input"
              type="email"
              required
              placeholder={EMAIL_PLACEHOLDER}
              onChange={(newVal) => {
                this.setState({email: newVal.target.value});
              }}
              onKeyDown={(e) => {
                if (!showPasswordScreen && e.key === 'Enter') {
                  this._getCode();
                }
              }}
              value={this.state.email}
            />
          </Control>
          <Help color={this.state.emailError?.color}>
            {this.state.emailError?.error}
          </Help>
          {!showPasswordScreen && (
            <Typography sx={{fontSize: 12, fontStyle: 'italic'}}>
              {CODE_INFO_TIP}
            </Typography>
          )}
        </Field>
        {showPasswordScreen && (
          <Field>
            <Stack direction="row" justifyContent="space-between">
              <Label style={{color: Palette.PRIMARY}}>Password</Label>
              <div className={styles.forgotPassword}>
                <Link to="/reset-password" tabIndex="3">
                  Forgot Password?
                </Link>
              </div>
            </Stack>

            <Control>
              <Input
                color={this.state.emailError?.color}
                data-testid="password-input"
                type="password"
                required
                placeholder={PASSWORD_PLACEHOLDER}
                onChange={(newVal) => {
                  this.setState({password: newVal.target.value});
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this._loginWithPassword();
                  }
                }}
                value={this.state.password}
              />
            </Control>
            <Help color={this.state.passwordError?.color}>
              {this.state.passwordError?.error}
            </Help>
          </Field>
        )}
        <Stack direction="row">
          {!showPasswordScreen ? (
            <Button
              color="primary"
              data-testid="get-code-button"
              disabled={
                !this.state.touAgree || this._checkForErrors('email')
                  ? true
                  : false
              }
              onClick={this._getCode}>
              Get Code
            </Button>
          ) : (
            <Button
              color="primary"
              data-testid="login-with-password-button"
              disabled={
                !this.state.touAgree ||
                this._checkForErrors('email') ||
                this._checkForErrors('password')
                  ? true
                  : false || this.state.loading
              }
              onClick={this._loginWithPassword}>
              Submit
            </Button>
          )}
          <Stack sx={{ml: 'auto'}}>
            {this.state.isLoginWithCode && (
              <Button
                inverted
                color="primary"
                data-testid="login-with-password-button"
                onClick={this._toggleLogin}>
                Login with password
              </Button>
            )}
            {showPasswordScreen && (
              <Stack direction="row" alignItems="center">
                <Button
                  data-testid="login-with-code-button"
                  inverted
                  color="primary"
                  onClick={this._toggleLogin}>
                  Login with code
                </Button>
                <Tooltip
                  arrow
                  disableFocusListener
                  title={CODE_INFO_TIP}
                  placement="top">
                  <CodeInfoDiv>
                    <Icon name={IconNames.HelpCircle} />
                  </CodeInfoDiv>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </Stack>
        {this.state.loading && (
          <Stack direction="row" justifyContent="center">
            <Progress color="primary" className={styles.spinner} />
          </Stack>
        )}
      </div>
    );
  };

  render() {
    const showLockMessage =
      !this.state.isLoginWithCode && this.state.showLockMessage;

    return (
      <div className={styles.container}>
        {showLockMessage && (
          <Box className={styles.lockMessage}>
            Your account has been locked due to too many failed attempts. To
            unlock your account please:
            <div>
              <Link onClick={this._toggleLogin} to="">
                Login with Code
              </Link>
              {' or '}
              <Link to="/reset-password">Reset your Password</Link>
            </div>
          </Box>
        )}
        <Box className={styles.content}>
          <Heading size={3} style={{color: Palette.PRIMARY}}>
            Login
          </Heading>
          {this._loginStage()}
        </Box>

        {!this.props.meStore.isAuthenticated() && (
          <div className={styles.content}>
            <div className={styles.checkboxContainer}>
              <Checkbox
                className={styles.checkbox}
                checked={this.state.touAgree}
                value={this.state.touAgree}
                data-testid="touAgree"
                disabled={this.state.loading}
                onChange={(event) => {
                  this.setState({touAgree: event.target.checked});
                }}
              />
              <label htmlFor="touAgree">
                By checking this box, you acknowledge that you have read the{' '}
                <a href="https://www.example.com/terms-of-use">Terms of Use</a>{' '}
                and{' '}
                <a href="https://www.example.com/privacy-policy">
                  Privacy Policy
                </a>
                . You also (1) acknowledge that you understand both documents,
                (2) agree to be bound by the Terms of Use, and (3) acknowledge
                that, by proceeding, you consent to the processing of your
                personal information as described in the Privacy Policy.
              </label>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  inject('meStore', 'eventStore')(observer(LoginModule)),
);
