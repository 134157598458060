import React from 'react';
import * as Sentry from '@sentry/react';
import Moment from 'react-moment';
import moment from 'moment-timezone';

import {CompanionEventSources, Events} from '@wellstone-solutions/common';
import {EventEmitter} from './EventEmitter';
import {months} from '../constants/Months';
import {days} from '../constants/Days';
import {hours} from '../constants/Hours';
import {chartProps} from '../constants/ChartProps';
import {Checkins} from '@wellstone-solutions/common';

// for bar charts and other collections of multiple event types visualized together in single chart
export const buildLegendList = async (data, stackTypes) => {
  let legendList;
  if (typeof data[0] === 'string') {
    legendList = [];
    await data.forEach((prop, p) => {
      legendList.push(chartProps[prop]);
    });
  } else {
    legendList = [];
    await Object.keys(data).forEach((prop, p) => {
      if (
        chartProps[prop] &&
        (!stackTypes || stackTypes.indexOf(chartProps[prop].stack) > -1)
      ) {
        legendList.push(chartProps[prop]);
      }
    });
  }
  return legendList;
};

// creates the necessary range that serves as the mapping axis for a chart
export const createRangeArray = (start, end, by) => {
  let arr = [];

  let endObj = {
    year: end.getFullYear(),
    month: end.getMonth(),
    day: end.getDate(),
  };

  let startDate = {
    year: start.getFullYear(),
    month: start.getMonth(),
    day: start.getDate(),
  };

  let countingDate = new Date(startDate.year, startDate.month, startDate.day);

  switch (by) {
    case 'year':
      while (countingDate.getFullYear() <= endObj.year) {
        arr.push(countingDate.getFullYear());
        countingDate.setFullYear(countingDate.getFullYear() + 1);
      }
      break;
    case 'date':
      while (countingDate <= end) {
        let d =
          countingDate.getMonth() +
          1 +
          '/' +
          countingDate.getDate() +
          '/' +
          countingDate.getFullYear();
        arr.push(d);
        countingDate.setDate(countingDate.getDate() + 1);
      }
      break;
    case 'day':
      arr = days;
      break;
    case 'hour':
      arr = hours;
      break;
    default:
      // month
      while (
        countingDate.getFullYear() <= endObj.year &&
        countingDate.getMonth() <= endObj.month
      ) {
        let m =
          months[countingDate.getMonth()] + ' ' + countingDate.getFullYear();
        arr.push(m);
        countingDate.setMonth(countingDate.getMonth() + 1);
      }
      break;
  }
  return arr;
};

const getTime = (date) => {
  return (
    (date.getHours() < 10 ? '0' : '') +
    date.getHours() +
    ':' +
    (date.getMinutes() < 10 ? '0' : '') +
    date.getMinutes()
  );
};

const mapSourceToLegacySource = (source) => {
  switch (source) {
    case CompanionEventSources.CHAT_SCREEN:
      return 'chat';
    case CompanionEventSources.MEETING_SCREEN:
      return 'my_meetings';
    case CompanionEventSources.RESOURCES_SCREEN:
      return 'resources';
    case CompanionEventSources.MY_RESOURCES_SCREEN:
      return 'saved_resources';
  }

  return source;
};

const toLegacyEventCategory = (prefix, data) => {
  const {action = null, source = null, type = null} = data;

  const suffixParts = [];
  if (action) {
    suffixParts.push(action);
  }
  if (type) {
    suffixParts.push(type);
  }
  if (source) {
    suffixParts.push(`from_${mapSourceToLegacySource(source)}`);
  }
  const suffix = suffixParts.join('_');

  return Events[`${prefix}_${suffix}`.toUpperCase()];
};

export const normalizeEvents = (events) =>
  events.map((event) => {
    switch (event.category) {
      case Events.USER_SHARED_RESOURCE:
        event.category = toLegacyEventCategory(
          Events.USER_SHARED_RESOURCE,
          event.data,
        );
        break;
      case Events.USER_SHARED_MEETING:
        event.category = toLegacyEventCategory(
          Events.USER_SHARED_MEETING,
          event.data,
        );
        break;
    }

    return event;
  });

// takes the UTC dateTime and creates all relevant local date and time data
export const formatEvents = (
  events,
  category,
  state,
  continuationObject = false,
) => {
  const normalizedEvents = normalizeEvents(events);

  let eventsList = [];
  normalizedEvents.forEach((event, e) => {
    if (!category || event.category === category) {
      if (!state || event.data.states.indexOf(state) > -1) {
        const localDate = moment.utc(event.created);
        if (event.time_zone) {
          localDate.tz(event.time_zone);
        }
        event.localDate = new Date(localDate);
        event.month = localDate.format('M') - 1;
        event.day = days[localDate.format('e')];
        event.year = localDate.format('YYYY');
        event.hour = hours[localDate.format('H')];
        event.localTime = getTime(new Date(localDate));
        eventsList.push(event);
      }
    }
  });

  if (continuationObject) {
    return eventsToSeries(eventsList, category, continuationObject);
  } else {
    return eventsList;
  }
};

// collection of all events can be broken up by category, returned as separate arrays
export const splitEventsByEventType = (arr) => {
  return arr.reduce(function (memo, evt) {
    if (!memo[evt.category]) {
      memo[evt.category] = [];
    }
    memo[evt.category].push(evt);
    return memo;
  }, {});
};

export const eventsToSeries = (data, category, continuationObject) => {
  let arr = [];
  data.map((event, e) => {
    let eObj = eventToChartData(event, 1, category);
    arr.push(eObj);
    return data;
  });
  if (continuationObject) {
    return aggregateData(arr, category, continuationObject);
  } else {
    return arr;
  }
};

// takes the simple data object and applies various dateTime info so that any parsing of data is possible
export const eventToChartData = (event, value, category, index) => {
  let obj = {
    date:
      event.localDate.getMonth() +
      1 +
      '/' +
      event.localDate.getDate() +
      '/' +
      event.localDate.getFullYear(),
    time: event.localTime,
    hour: event.hour,
    day: event.day,
    month: months[event.month] + ' ' + event.year,
    year: event.year,
    user: event.created_by_id,
  };

  if (category) {
    obj = {...obj, ...{[category]: value}};
  }

  if (index) {
    obj.index = index;
  }

  if (value) {
    obj.value = value;
  }

  return obj;
};

// specific function used for taking a collection of checkins and separating them
// (duplicates where necessary) so that each sentiment can be tracked separately
export const splitCheckinsByState = (checkins) => {
  let states = [];
  states = Checkins.map((state, s) => {
    return {
      name: state.value.toUpperCase(),
      color: state.color,
      data: [],
    };
  });
  checkins.forEach((checkin, c) => {
    states.forEach((state) => {
      if (checkin.data.states.indexOf(state.name.toUpperCase()) > -1) {
        let cObj = eventToChartData(checkin, 1, null, 1);
        state.data.push(cObj);
      }
    });
  });
  return states;
};

// interior function for aggregation process
const aggregate = (arr, by, aggIndex, aggValue, value = 'value') => {
  return arr.reduce(function (memo, evt) {
    if (!memo[evt[by]]) {
      memo[evt[by]] = {
        [by]: evt[by],
        [value + '_userlist']: [],
        index: 0,
        [value]: 0,
      };
    }

    memo[evt[by]].index = aggIndex ? memo[evt[by]].index + evt.index : 1;
    if (memo[evt[by]][value + '_userlist'].indexOf(evt.user) < 0) {
      memo[evt[by]][value + '_userlist'].push(evt.user);
    }
    memo[evt[by]][value] = aggValue ? memo[evt[by]][value] + evt[value] : 1;
    return memo;
  }, {});
};

// with the above function aggregates data based on whatever param is being used to visualize the data
// export const aggregateData = (arr, by, aggIndex, aggValue, value="value") => {
export const aggregateData = (arr, value, continuationObject) => {
  let obj = aggregate(
    arr,
    continuationObject.by,
    continuationObject.aggIndex,
    continuationObject.aggValue,
    value,
  );

  let dArr = [];
  for (var prop in obj) {
    dArr.push(obj[prop]);
  }
  if (continuationObject.range) {
    return syncDataToRange(dArr, value, continuationObject);
  } else {
    return dArr;
  }
};

// There will be situations where there is no data present, we want the chart to fill
// in empty gaps, whether it is time of day ranges, days of week, or dates
export const syncDataToRange = (data, value = 'value', continuationObject) => {
  let arr = [];
  continuationObject.range.map((increment, i) => {
    let found = null;
    data.map((dataPoint, dp) => {
      if (increment === dataPoint[continuationObject.by]) {
        found = dataPoint;
      }
      return data;
    });

    // shorten the date to (MM/DD) IF there are too many entries
    let incrBy = increment;
    if (continuationObject.range.length > 24) {
      incrBy = increment.slice(0, increment.lastIndexOf('/'));
    }

    // IF data point found add it, ELSE push an empty node
    if (found) {
      found[continuationObject.by] = incrBy;
      arr.push(found);
    } else {
      arr.push({
        [continuationObject.by]: incrBy,
        index: 0,
        [value]: 0,
      });
    }
    return continuationObject.range;
  });
  return arr;
};

export const combineSeries = (multiSeries) => {
  let arr = [];
  multiSeries[0].forEach((time, t) => {
    let obj = time;
    multiSeries.forEach((series, s) => {
      let next = series[t];
      obj = {...obj, ...next};
    });
    arr.push(obj);
  });
  return arr;
};

const percentColors = [
  {pct: 1.0, color: {r: 0xcc, g: 0x00, b: 0}}, // BAD
  {pct: 0.5, color: {r: 0x99, g: 0x55, b: 0}},
  {pct: 0.0, color: {r: 0x00, g: 0xcc, b: 0}}, // GOOD
];

export const getSeverityColor = (val, max, min = 0) => {
  let pct = val / (max - min);
  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct <= percentColors[i].pct) {
      break;
    }
  }
  var lower = percentColors[i - 1];
  var upper = percentColors[i];
  var range = upper.pct - lower.pct;
  var rangePct = (pct - lower.pct) / range;
  var pctLower = 1 - rangePct;
  var pctUpper = rangePct;
  var color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };
  return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  // or output as hex if preferred
};

// TABLE CONTROLS
export const showField = (hide, render) => {
  switch (hide) {
    case true:
      return null;
    default:
      return render;
  }
};

export const showLabel = (hide, label, abbr) => {
  switch (hide) {
    case true:
      return null;
    default:
      return (
        <th>
          <abbr title={label}>{abbr || label}</abbr>
        </th>
      );
  }
};

export const handleDateSelect = (ranges, context) => {
  const {startDate, endDate} = ranges.selection || ranges.range1;

  // TODO: (maybe) can't select a single day currently, fix?
  if (startDate === endDate) {
    let newState = context.state;
    newState.selectionRange.startDate = startDate;
    context.setState({
      selectionRange: {
        startDate,
        endDate: context.state.selectionRange.endDate,
      },
    });
  } else {
    context.setState(
      {
        selectionRange: {
          startDate,
          endDate,
        },
      },
      () => {
        //context.dateRangeModal.current.close();
        EventEmitter.dispatch('closeModal');
        context._getData();
      },
    );
  }
};

export const handleRawDateSelect = (ranges) => {
  if (ranges.selection.startDate === ranges.selection.endDate) {
    return ranges.selection;
  } else {
    EventEmitter.dispatch('closeModal');
    return ranges.selection;
  }
};

export const getDateRange = (context) => {
  if (context.state.selectionRange) {
    let start = context.state.selectionRange.startDate;
    let end = context.state.selectionRange.endDate;
    return (
      <div>
        <Moment element="span" format="MMM D, YYYY" date={start} /> -{' '}
        <Moment element="span" format="MMM D, YYYY" date={end} />
      </div>
    );
  }
};

export const getRawDateRange = (start, end) => {
  return (
    <div>
      <Moment element="span" format="MMM D, YYYY" date={start} /> -{' '}
      <Moment element="span" format="MMM D, YYYY" date={end} />
    </div>
  );
};

export const processData = async (data, settings) => {
  let range = createRangeArray(
    settings.selectionRange.startDate,
    settings.selectionRange.endDate,
    settings.aggregator,
  );

  // CATEGORIZED CHECKINS

  let checkins = formatEvents(data.data.events, 'user_added_checkin');
  let byState = splitCheckinsByState(checkins);

  let checkinData = byState.map((state, s) => {
    let series = aggregateData(state.data, 'value', {
      by: settings.aggregator,
      aggIndex: false,
      aggValue: true,
    });
    return {
      name: state.name,
      color: state.color,
      data: syncDataToRange(series, 'value', {
        range: range,
        by: settings.aggregator,
      }),
    };
  });

  let continuationObject = {
    by: settings.aggregator,
    aggIndex: false,
    aggValue: true,
    range: range,
  };

  // ALL ACTIVITY EVENTS
  let sEBC = splitEventsByEventType(data.data.events);
  let charts = {};
  for (const [key, value] of Object.entries(sEBC)) {
    if (chartProps[key]) {
      chartProps[key].charts.forEach((chart) => {
        if (!charts[chart]) {
          charts[chart] = {
            events: [],
            stacks: [],
          };
        }
        charts[chart].events = [...charts[chart].events, ...value];
        if (charts[chart].stacks.indexOf(chartProps[key].stack) < 0) {
          charts[chart].stacks.push(chartProps[key].stack);
        }
      });
    }
  }

  for (const [, value] of Object.entries(charts)) {
    value.legend = await buildLegendList(sEBC, value.stacks);
    let formatted = value.legend.map((obj) => {
      return formatEvents(value.events, obj.key, false, continuationObject);
    });

    value.series = combineSeries(formatted);
  }

  // // UPDATE STATE
  return {
    activityData: charts.activity,
    badgeData: charts.badge,
    staffData: charts.staff,
    appnavigationData: charts.appnavigation,
    engagementData: charts.engagement,
    checkinData: checkinData,
    rawEventData: data.data.events,
  };
};

export const processRawData = async (data, selectionRange, aggregator) => {
  let range = createRangeArray(
    selectionRange.startDate,
    selectionRange.endDate,
    aggregator,
  );

  // CATEGORIZED CHECKINS
  let checkins = formatEvents(data.data.events, 'user_added_checkin');
  let byState = splitCheckinsByState(checkins);

  let checkinData = byState.map((state, s) => {
    let series = aggregateData(state.data, 'value', {
      by: aggregator,
      aggIndex: false,
      aggValue: true,
    });
    return {
      name: state.name,
      color: state.color,
      data: syncDataToRange(series, 'value', {
        range: range,
        by: aggregator,
      }),
    };
  });

  let continuationObject = {
    by: aggregator,
    aggIndex: false,
    aggValue: true,
    range: range,
  };

  // ALL ACTIVITY EVENTS
  let sEBC = splitEventsByEventType(data.data.events);
  let charts = {};
  for (const [key, value] of Object.entries(sEBC)) {
    if (!chartProps[key]) {
      Sentry.captureMessage(key);
    } else {
      chartProps[key].charts.forEach((chart) => {
        if (!charts[chart]) {
          charts[chart] = {
            events: [],
            stacks: [],
          };
        }
        charts[chart].events = [...charts[chart].events, ...value];
        if (charts[chart].stacks.indexOf(chartProps[key].stack) < 0) {
          charts[chart].stacks.push(chartProps[key].stack);
        }
      });
    }
  }

  for (const [, value] of Object.entries(charts)) {
    value.legend = await buildLegendList(sEBC, value.stacks);
    let formatted = value.legend.map((obj) => {
      return formatEvents(value.events, obj.key, false, continuationObject);
    });

    value.series = combineSeries(formatted);
  }

  return {
    charts: charts,
    checkinData: checkinData,
    rawEventData: data.data.events,
  };
};
