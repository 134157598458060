// React
import React, {useEffect} from 'react';

// Third Party
import Alert from 'react-s-alert';
import {Provider} from 'mobx-react';
import {inject, observer} from 'mobx-react';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import useRoutingInstrumentation from 'react-router-v6-instrumentation';

// FF
import {TMP_BRIDGE_WRAPPER, hasFlag} from 'constants/FeatureFlags';

// WS
import {Api} from '@wellstone-solutions/common';
import {ThemeProvider} from '@wellstone-solutions/web';
import { SiteHeader } from 'modules/app/components/SiteHeader';
import {NavigationBar} from 'components/layout/NavigationBar';
import FooterBar from 'components/layout/FooterBar';
import PageContainer from 'components/layout/PageContainer';
import Tutorial from 'components/tutorial/';
import {baseUrl, proxy, handleResponse, handleErrors} from 'api';

// mobx storage
import RootStore from 'mobx/RootStore';

// Assets
import 'App.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import ScrollTo from './components/tutorial/scrollto';

const isDevelopment =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const envSuffixMap = {
  prod: 'P',
  dev: 'Dev',
  demo: 'Demo',
  local: 'L',
};

const envSuffix = envSuffixMap[process.env.REACT_APP_ENV] ?? '';
const bridgeVersion = `v${process.env.REACT_APP_BRIDGE_VERSION} ${envSuffix}`;

let dsn;
let tracesSampleRate = 0;

if (!isDevelopment) {
  dsn =
    'https://d5a73755bcc54c2aa8ff4365f87441f7@o294755.ingest.sentry.io/5377541';
  tracesSampleRate = 0.3;
}

const apiVersions = [Api.API_VERSIONS.V1, Api.API_VERSIONS.REST];
apiVersions.forEach((version) => {
  Api.configure({
    baseURL: baseUrl,
    version,
    headers: {
      common: {
        'x-client-version':
          'bridge,' + (process.env.REACT_APP_BRIDGE_VERSION || ''),
        'x-user-time-zone': moment.tz.guess(true),
      },
    },
  });
  Api.Instance.current(version).interceptors.response.use(
    handleResponse,
    handleErrors,
  );
});

const App = () => {
  // Initialize Sentry with the browser tracing integration.
  const routingInstrumentation = useRoutingInstrumentation();
  useEffect(() => {
    const browserTracing = new Integrations.BrowserTracing({
      routingInstrumentation,
    });
    Sentry.init({
      dsn,
      environment: process.env.REACT_APP_ENV,
      integrations: [browserTracing],
      tracesSampleRate,
      ignoreErrors: [/^Api Call Failed: Status: 401/],
    });
  }, [routingInstrumentation]);

  const rootStore = RootStore.getInstance();
  proxy.stores.meStore = rootStore.stores.meStore;
  proxy.stores.tutorialStore = rootStore.stores.tutorialStore;
  proxy.stores.appStore = rootStore.stores.appStore;
  
  return (
    <ThemeProvider>
      <Provider {...rootStore.stores}>
        <Main />
      </Provider>
    </ThemeProvider>
  );
};

const Main = inject('tutorialStore')(
  observer(({tutorialStore}) => {
    const {isComplete} = tutorialStore;
    
    return (
      <div data-testid="app-main" style={styles.wrapper}>
        <div style={isComplete ? styles.fullPage : styles.anchorLeft}>
          <ScrollTo stepId="move-to-groups" />
          {hasFlag(TMP_BRIDGE_WRAPPER, proxy.stores.meStore.features) ? 
            <SiteHeader /> : 
            <NavigationBar />
          }
          <PageContainer />
          <Alert stack={{limit: 3}} html={true} />
          <FooterBar version={bridgeVersion} />
        </div>
        <Tutorial />
      </div>
    );
  }),
);

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'row',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    flexFlow: 'row no-wrap',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  fullPage: {
    width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  anchorLeft: {
    width: '72vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
};

export default App;
