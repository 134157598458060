// @flow
import React from 'react';
import type {Node} from 'react';
// import {ResourcesContext} from '../../contexts';

export const SavedResources = (): Node => {
  // add this back in with proper destructuring when building out this component
  // const {} = useContext(ResourcesContext);

  return <div>Saved Resources Component</div>;
};
