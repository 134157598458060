// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import type {FormType} from '@wellstone-solutions/common';
import {Alert, Checkbox, Stack} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {uniqueBy} from 'utils/uniqueBy';

type PropsType = {
  form: FormType<any>,
};

export const ProgramsStep = ({form}: PropsType): Node => {
  const [hasPrograms, setHasPrograms] = useState(true);
  const {meStore} = useStores();

  useEffect(() => {
    // If form is valid, we've already entered data
    // and now are returning, let's not overwrite everything
    // $FlowIgnoreMe
    if (form.isValid) {
      return;
    }

    // Preselect the first program if there is only one
    const availablePrograms = meStore.myPrograms.filter(
      (myProgram) =>
        !form.values.programs.find(
          ({id, existing}) => myProgram.id === id && existing,
        ),
    );

    if (availablePrograms.length === 1) {
      form.setFieldValue(
        'programs',
        uniqueBy(
          [
            ...form.values.programs,
            {id: availablePrograms[0].id, existing: false},
          ],
          'id',
        ),
      );
    }

    setHasPrograms(availablePrograms.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack sx={{px: 1}}>
      {!hasPrograms && (
        <Alert severity="info">
          There are no additional programs to assign.
        </Alert>
      )}
      {meStore.myPrograms.map((program) => (
        <div key={program.id}>
          <Checkbox
            checked={form.values.programs.some(({id}) => id === program.id)}
            data-testid={`checkbox-${program.id}`}
            disabled={form.values.programs.some(
              ({id, existing}) => id === program.id && existing,
            )}
            label={program.name}
            onChange={(event) => {
              // Update the form values
              if (event.target.checked) {
                form.setFieldValue(
                  'programs',
                  uniqueBy(
                    [
                      ...form.values.programs,
                      {id: program.id, existing: false},
                    ],
                    'id',
                  ),
                );
              } else {
                form.setFieldValue(
                  'programs',
                  form.values.programs.filter(({id}) => id !== program.id),
                );
              }

              // When changing programs, let's clear out groups
              form.setFieldValue('groups', []);
            }}
          />
        </div>
      ))}
    </Stack>
  );
};
