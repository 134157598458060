// @flow
import React from 'react';
import type {Node} from 'react';
import {
  Box,
  Button,
  ErrorMessage,
  FormRow,
  Stack,
  TextField,
  Typography,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import type {
  ApiExternalRelationshipType,
  UIIntegrationType,
} from '@wellstone-solutions/common';
import {CompletedRow} from './CompletedRow';

import {EHR_INPUT_EXAMPLES, INTEGRATION_STATUSES} from '../constants';
import type {IntegrationSearchStatusType} from '../types';

type PropsType = {
  form: any,
  handleSearch?: () => Promise<void>,
  memberIntegration: ApiExternalRelationshipType,
  memberType: string,
  orgIntegration: UIIntegrationType,
  status: IntegrationSearchStatusType,
  setStatus: (status: IntegrationSearchStatusType) => void,
};

export const FormInfoStatus = ({
  form,
  handleSearch,
  orgIntegration,
  memberIntegration,
  memberType,
  status,
  setStatus,
}: PropsType): Node => {
  const {
    READY,
    CONNECTED,
    SEARCHING,
    ERROR,
    CONFIRM,
    TIP,
  } = INTEGRATION_STATUSES;
  const {externalRelationshipId} = form.values;
  const ehrLabel = `${orgIntegration?.name}`;

  const instructLabel = `Enter the ${
    memberType === 'member' ? `member's` : `staff member's`
  } ${orgIntegration.name} ID so we can find the exact match.`;

  const inputPlaceholder = `${
    memberType === 'member' ? `Member's` : `Staff Member's`
  } ${orgIntegration?.name} ID`;

  const exampleText = EHR_INPUT_EXAMPLES?.[orgIntegration.name]?.[memberType];

  return (
    <Stack sx={styles.container}>
      {status === READY && (
        <Typography sx={{fontSize: 14}}>{instructLabel}</Typography>
      )}
      {status === SEARCHING && (
        <Typography
          sx={{
            fontWeight: 'bold',
            color: Theme.colorPalette.darkest,
            fontStyle: 'italic',
          }}>
          Looking for {externalRelationshipId}
        </Typography>
      )}
      {status === CONNECTED && (
        <>
          <CompletedRow label="Name" value={memberIntegration.name} />
          <CompletedRow label="Email" value={memberIntegration.email} />
          <CompletedRow label="Birthdate" value={memberIntegration.birthdate} />
          <CompletedRow
            label={`${ehrLabel} ID`}
            value={memberIntegration.external_relationship_id}
          />

          <Typography sx={{marginTop: 2}}>
            Not the correct person?
            <Button
              variant="text"
              onClick={() => {
                setStatus(READY);
              }}
              sx={{
                textTransform: 'capitalize',
                color: Theme.colorPalette.blue,
                fontWeight: 'bold',
              }}>
              Search again
            </Button>
          </Typography>
        </>
      )}
      {status === CONFIRM && (
        <Stack direction="row">
          <Typography
            sx={{
              fontWeight: 'bold',
              color: Theme.colorPalette.darkest,
            }}>
            {`${orgIntegration.name} ID:`}
          </Typography>
          <Typography sx={{ml: 1}}>{externalRelationshipId}</Typography>
        </Stack>
      )}
      {status === TIP && (
        <Stack direction="column">
          <CompletedRow label={`${ehrLabel} ID`} value={'Not Connected'} />
          <Typography sx={{marginTop: 2}}>
            {`Connecting the ${
              memberType === 'staff' ? 'staff' : ''
            } member's profile will synchronize records between
            Bridge and ${ehrLabel}. You can connect later on from the ${
              memberType === 'staff' ? 'staff' : ''
            }member's
            profile page by editing the profile and entering the ${ehrLabel} ID.`}
          </Typography>
        </Stack>
      )}

      {status === ERROR && (
        <Box sx={{mb: 3}}>
          <Typography
            sx={{
              color: Theme.colorPalette.darkest,
              fontWeight: 'bold',
            }}>
            {`We did not find a match for ${externalRelationshipId}.`}
            <Typography>Try searching again</Typography>
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          flexDirection: 'row',
          // rerendering this conditionally causes React errors, so using display
          display: [READY, ERROR].includes(status) ? 'flex' : 'none',
        }}>
        <FormRow sx={{flex: 3, mt: 2}}>
          <TextField
            autoFocus
            formField="externalRelationshipId"
            onChange={form.handleFieldChange('externalRelationshipId')}
            value={externalRelationshipId}
            label={inputPlaceholder}
            sx={styles.input}
          />
          <Typography
            sx={{
              ml: 1.5,
              mt: 0.5,
              fontSize: 12,
              color: Theme.colorPalette.outline,
            }}>
            {exampleText}
          </Typography>
          <ErrorMessage
            name="integrationId"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <Button
          variant="contained"
          disabled={!externalRelationshipId}
          sx={styles.button}
          onClick={handleSearch}>
          Search
        </Button>
      </Box>
    </Stack>
  );
};

const styles = {
  button: {
    padding: 0,
    height: 40,
    fontSize: 16,
    marginVertical: 'auto',
    marginLeft: 2,
    // marginRight: 4,
    alignSelf: 'center',
    flex: 1,
    backgroundColor: Theme.colorPalette.primary,
    color: Theme.colorPalette.lightest,
    borderRadius: 10,
    textTransform: 'capitalize',
    px: 2,
  },
  completed: {
    display: 'inline',
  },
  input: {backgroundColor: Theme.colorPalette.lightest},
  container: {
    color: Theme.colorPalette.darker,
    ml: 1,
    flex: 2,
    justifyContent: 'space-around',
  },
};
