// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Theme} from '@wellstone-solutions/common';
import {Icon, IconNames, Stack} from '@wellstone-solutions/web';
import {PfButton} from 'components/shared/PfButton';
import {DOCUMENT_STATUSES} from 'modules/documentation/constants';
import {Typography} from '@wellstone-solutions/web/dist/Typography/Typography';
import {PfConfirmInline} from 'components/shared/PfConfirmInline';

type PropsType = {
  formId: string,
  form: any,
  onSave?: () => Promise<void>,
  onDelete: () => Promise<void>,
  isSaving?: boolean,
};

export const FormActionButtons = ({
  formId,
  form,
  onSave,
  onDelete,
  isSaving = false,
}: PropsType): Node => {
  const theme = useTheme();
  const smallBreakPoint = useMediaQuery(theme.breakpoints.down('sm'));
  const medBreakPoint = useMediaQuery(theme.breakpoints.down('md'));
  const [showInlineConfirm, setShowInlineConfirm] = useState(false);
  const isDraft = form.values.status === DOCUMENT_STATUSES.draft;
  const isEditable = [
    DOCUMENT_STATUSES.draft,
    DOCUMENT_STATUSES.error,
    DOCUMENT_STATUSES.reverted,
  ].includes(form.values.status);
  const canPublish = !form.dirty && isEditable;
  const canDelete = isDraft;

  const actionButtonStyles = smallBreakPoint
    ? styles.actionButtonSmall
    : styles.actionButton;

  const hideActionButtons = medBreakPoint && showInlineConfirm;

  return (
    <Stack
      direction="row"
      sx={{
        height: Theme.spacing.monster,
        mt: 4,
      }}>
      {!hideActionButtons && (
        <>
          <PfButton
            isDisabled={!form.dirty || form.isSubmitting || isSaving}
            outlined={true}
            inverted={true}
            color="primary"
            data-testid="action-button-save"
            loading={isSaving}
            onClick={onSave}
            style={{
              ...styles.leftButton,
              ...styles.publishButton,
              ...actionButtonStyles,
            }}>
            {form.dirty ? 'Save' : 'Saved!'}
          </PfButton>
          <PfButton
            isDisabled={!canPublish}
            color="primary"
            style={actionButtonStyles}
            data-testid="action-button-preview"
            type="submit"
            form={formId}>
            Preview
          </PfButton>
        </>
      )}
      <Stack
        direction="row"
        sx={{
          flex: 1,
          justifyContent: 'flex-end',
          color: Theme.colorPalette.red,
        }}>
        {!showInlineConfirm && (
          <PfButton
            noStyles
            isDisabled={!canDelete}
            style={styles.deleteButton}
            data-testid="action-button-delete"
            loading={form.isSubmitting}
            onClick={() => setShowInlineConfirm(true)}>
            <Icon
              name={IconNames.Trash}
              size={20}
              color={Theme.colorPalette.primary}
              style={styles.icon}
            />

            <Typography
              sx={{color: Theme.colorPalette.primary, fontWeight: 'bold'}}>
              Delete
            </Typography>
          </PfButton>
        )}

        <PfConfirmInline
          showConfirm={showInlineConfirm}
          cancelConfirm={() => setShowInlineConfirm(false)}
          confirm={onDelete}
          style={styles.confirmButton}
          confirmText="Are you sure you want to delete this?"
          confirmTextStyle={{color: Theme.colorPalette.primary}}
        />
      </Stack>
    </Stack>
  );
};

const styles = {
  leftButton: {
    marginRight: '32px',
  },
  publishButton: {
    borderColor: Theme.colorPalette.primary,
    color: Theme.colorPalette.primary,
  },
  actionButton: {
    paddingLeft: Theme.spacing.gap,
    paddingRight: Theme.spacing.gap,
  },
  actionButtonSmall: {
    paddingLeft: Theme.spacing.medium,
    paddingRight: Theme.spacing.medium,
    marginRight: Theme.spacing.average,
  },
  deleteButton: {
    justifyContent: 'flex-end',
    color: 'black',
  },
  confirmButton: {height: 40, marginLeft: 8},
  icon: {marginRight: 4, strokeWidth: 2},
};
