// React
import React from 'react';
import {inject, observer} from 'mobx-react';

// WS
import {SECURITY_IDLE_TIMER, hasFlag} from 'constants/FeatureFlags';
import {Routes as SharedRoutes} from 'components/Routes';
import {NotFound} from 'components/NotFound';
import {withRouter} from 'components/withRouter';
import {IdleTimer} from 'components/IdleTimer';
import RootStore from 'mobx/RootStore';
import PfLoader from '../shared/PfLoader';
import PublicFrame from 'pages/Public';
import ResetFrame from 'pages/Reset';
import SystemFrame from 'pages/System';
import {AuthorizedFrame} from 'pages/AuthorizedFrame';
import {MaintenanceMode} from '../MaintenanceMode/MaintenanceMode';

export const routes = [
  {
    Component: PublicFrame,
    isProtected: false,
    path: '/auth/*',
  },
  {
    Component: ResetFrame,
    isProtected: false,
    path: '/reset-password',
  },
  {
    Component: SystemFrame,
    isProtected: true,
    path: '/system/*',
  },
  {
    Component: AuthorizedFrame,
    isProtected: true,
    path: '/*',
  },
  {
    Component: NotFound,
    isProtected: false,
    path: '*',
  },
];

class PageContainer extends React.Component {
  state = {
    events: {},
  };

  async componentDidMount() {
    const {router, tutorialStore} = this.props;

    tutorialStore.setRouter(router);

    await RootStore.getInstance().init();
  }

  render() {
    if (this.props.appStore.apiMaintenanceMode) {
      return <MaintenanceMode />;
    }

    const showLoader =
      this.props.meStore.isSigningOut || !RootStore.getInstance().isReady;

    const isLocalEnvironment = process.env.REACT_APP_ENV === 'local';

    const useIdleTimer =
      !isLocalEnvironment &&
      this.props.meStore.isAuthenticated() &&
      hasFlag(SECURITY_IDLE_TIMER, this.props.meStore.features);

    return (
      <div style={styles.pageContent}>
        {useIdleTimer && <IdleTimer />}
        {showLoader ? <PfLoader /> : <SharedRoutes routes={routes} />}
      </div>
    );
  }
}
const styles = {
  pageContent: {
    flexGrow: 1,
    display: 'flex',
    overflowX: 'auto',
  },
};

export default withRouter(
  inject('meStore', 'tutorialStore', 'appStore')(observer(PageContainer)),
);
