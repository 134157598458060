// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import type {
  FormType,
  UIExternalRelationshipType,
} from '@wellstone-solutions/common';
import {Box, Button, Stack, Typography} from '@wellstone-solutions/web';
import {EHRWrapper} from 'modules/integration/components';

type PropsType = {
  form: FormType<any>,
  memberType: string,
  step: any,
  onNext: () => void,
};

export const IntegrationStep = ({
  form,
  memberType = 'member',
  step,
  onNext,
}: PropsType): Node => {
  // ehrData is the response data from EHR provider
  const [ehrData, setEHRData] = useState<UIExternalRelationshipType>({});
  const showNonEHROption = Object.keys(ehrData).length === 0;

  const orgLabel = step.data.orgIntegration?.name;

  return (
    <Stack direction="column">
      <EHRWrapper
        form={form}
        memberType="member"
        orgIntegration={step.data.orgIntegration}
        setIntegration={setEHRData}
      />

      {/* Hide the option to use email if external integration found */}
      {showNonEHROption && (
        <Box sx={{mt: 4, textAlign: 'center'}}>
          <Typography
            sx={{fontWeight: 'bold', color: Theme.colorPalette.darkest}}>
            {`Don't have the ${orgLabel} ID handy?`}
          </Typography>
          <Typography sx={{color: Theme.colorPalette.darker}}>
            You can create a profile using the member's email.
          </Typography>
          <Button
            sx={{textTransform: 'none'}}
            onClick={() => {
              step.data.setIsIntegrationOption(false);
            }}>
            Use member's email
          </Button>
        </Box>
      )}

      {/* Footer and onNext handled manually due to complexity in this step*/}
      {!showNonEHROption && (
        <Box sx={{mt: 4}}>
          <Button variant="contained" sx={styles.nextButton} onClick={onNext}>
            Next
          </Button>
        </Box>
      )}
    </Stack>
  );
};

const styles = {
  nextButton: {
    borderRadius: 10,
    fontWeight: 'bold',
    px: 4,
    textTransform: 'capitalize',
  },
};
